import {Button} from '@app/components/common/buttons/Button/Button';
import {BaseButtonsForm} from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import {BaseForm} from '@app/components/common/forms/BaseForm/BaseForm';
import {Select} from '@app/components/common/selects/Select/Select';
import {notificationController} from '@app/controllers/notificationController';
import {Col, Divider, Form, Row} from 'antd';
import {useResponsive} from 'hooks/useResponsive';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  LeadsForTeamDocument,
  LeadsForTrainingDocument,
  useLeadsForTeamQuery,
  useLeadsForTrainingQuery,
  useLeadVisitedFirstTrainingMutation,
  useNoviceVisitedFirstTrainingMutation,
  useUpdateLeadCancelFirstTrainingMutation,
} from '../lead/lead.generated';
import {useStaffAllQuery} from '../staff/staff.generated';
import {TrainingCoachesAndVisitsRawInput} from '../training/interface';
import {useUpdateTrainingsAndCoachesMutation} from '../training/training.generated';
import {Lead, LeadStatus, StudentVisit, Training} from '../types';
import * as S from './StudentVisitPanel.styles';
import {StudentVisitsByTrainingDocument, useStudentVisitsByTrainingLazyQuery,} from './studentVisit.generated';
import {StudentVisitItem} from "@app/components/dinamchiki/studentVisit/StudentVisitItem";
import {useAppSelector} from "@app/hooks/reduxHooks";
import {JsxElement} from "typescript/lib/tsserverlibrary";

interface StudentVisitPanelProps {
  training: Training;
}

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export type NovicesRawInput = {
  novices: Array<'StriEng'>;
};

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

export const StudentVisitPanel: React.FC<StudentVisitPanelProps> = ({ training }) => {
  const { isDesktop } = useResponsive();
  const [refetchNeeded, setRefetchNeeded] = useState<boolean>(false);
  const [studentVisits, setStudentVisits] = useState<StudentVisit[] | null | undefined>([]);
  const { t } = useTranslation();
  const [getStudentVisits, { data, refetch, called }] = useStudentVisitsByTrainingLazyQuery({ errorPolicy: 'all' });
  const [getLead] = useLeadVisitedFirstTrainingMutation({ errorPolicy: 'all' });
  const [getNovice] = useNoviceVisitedFirstTrainingMutation({ errorPolicy: 'all' });
  const [canselFirstTraining] = useUpdateLeadCancelFirstTrainingMutation({ errorPolicy: 'all' });
  const [form] = BaseForm.useForm();
  const [formNovice] = BaseForm.useForm();
  const { data: staffData, loading: staffLoading, error: staffError } = useStaffAllQuery({ errorPolicy: 'all' });
  const {
    data: leadsData,
    loading: leadsLoading,
    error: leadsError,
  } = useLeadsForTrainingQuery({ variables: { training: training.id }, errorPolicy: 'all' });
  const {
    data: teamLeadsData,
    loading: teamLeadsLoading,
    error: teamLeadsError,
  } = useLeadsForTeamQuery({ variables: { team: training.team!.id }, errorPolicy: 'all' });
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const user = useAppSelector((state) => state.user.user);

  const [trainingsAndCoachesMutation] = useUpdateTrainingsAndCoachesMutation({ errorPolicy: 'all' });
  useEffect(() => {
    if (refetchNeeded) {
      setRefetchNeeded(false);
      getStudentVisits({ variables: { training: training.id } }).then((res) => {
        setStudentVisits(res.data?.studentVisitsByTraining);
      });
      if (called) {
        refetch();
      } else {
        // getStudentVisits({ variables: { training: training } });
      }
    }
  }, [refetchNeeded, called, getStudentVisits, training.id, refetch]);

  useEffect(() => {
    getStudentVisits({ variables: { training: training.id } }).then((res) => {
      setStudentVisits(res.data?.studentVisitsByTraining);
    });
  }, []);

  const onCancelClick = (value: string, team: string | undefined | null): void => {
    const qry = { query: LeadsForTeamDocument, variables: { team: team } };
    const oldQry = { query: LeadsForTrainingDocument, variables: { training: training.id } };
    canselFirstTraining({
      variables: { id: value },
      refetchQueries: [oldQry, team ? qry : oldQry],
    }).then((res) => {
      if (res.errors) {
        res.errors?.map((item) => {
          notificationController.error({ message: item.message });
        });
      } else {
        setRefetchNeeded(true);
      }
    });
  };

  const refetchHandler = (value: boolean) => {
    setRefetchNeeded(value)
  }

  const colItems = useMemo(
    () =>
        user?.roles.find((item: string) => item === 'ADMIN') ?
      studentVisits?.map((item) =>
        <StudentVisitItem key={item.id} item={item} refetchHandler={refetchHandler}/>
      ) :
            studentVisits?.map((item) => item.student.published && (
                <StudentVisitItem key={item.id} item={item} refetchHandler={refetchHandler}/>
            )),
    [studentVisits, training],
  );

  const leadItems = useMemo(
    () =>
      leadsData?.leadsForTraining && leadsData.leadsForTraining.map((item) => (
        <Col key={item.id} span={24}>
          <S.ScreeningsRow
            justify={'space-between'}
            $isActive={true}
            wrap={false}
            onClick={() => onCancelClick(item.id, item.teamId)}
          >
            <Col>
              <Row gutter={[10, 0]} align="middle" wrap={false}>
                <Col>
                  <S.Name
                    $isPrimary={item.status === LeadStatus.FirstTraining}
                    $isSecondary={item.status === LeadStatus.FirstTrainingAdd}
                    $isWarning={item.status === LeadStatus.Contracted}
                  >
                    {item.name}
                  </S.Name>
                </Col>
              </Row>
            </Col>
          </S.ScreeningsRow>
        </Col>
      )),
    [leadsData?.leadsForTraining, onCancelClick],
  );

  const onFinish = async (values: TrainingCoachesAndVisitsRawInput) => {
    setLoading(true);
    trainingsAndCoachesMutation({
      variables: {
        input: {
          trainingId: training.id,
          headCoachID: values.headCoach,
          coachIds: values.coaches ? (values.coaches.length > 0 ? values.coaches : null) : null,
        },
      },
      refetchQueries: [{ query: StudentVisitsByTrainingDocument, variables: { training: training.id } }],
    }).then((res) => {
      setLoading(false);
      setFieldsChanged(false);
      notificationController.success({ message: t('common.successUpdate') });
    });
  };

  const onNoviceFinish = async (values: NovicesRawInput) => {
    for (const value of values.novices) {
      const regex = /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/gm;
      const found = value.match(regex);
      if (found != null) {
        getLead({
          variables: { input: { leadId: found[0], trainingId: training.id } },
          refetchQueries: [{ query: LeadsForTrainingDocument, variables: { training: training.id } }],
        });
      } else {
        getNovice({
          variables: { input: { name: value, trainingId: training.id } },
          refetchQueries: [{ query: LeadsForTrainingDocument, variables: { training: training.id } }],
        });
      }
      notificationController.success({ message: t('common.successUpdate') });
      formNovice.resetFields();
    }
  };

  return (
    <>
      <Row
        gutter={[
          { xs: 10, sm: 10, xl: 22 },
          { xs: 10, sm: 10, xl: 22 },
        ]}
      >
        <BaseButtonsForm
          {...formItemLayout}
          isFieldsChanged={isFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          name="validateForm"
          initialValues={{
            headCoach: training.headCoachId,
            coaches: training.coachIds ? training.coachIds : undefined,
          }}
          footer={
            <BaseButtonsForm.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {t('common.submit')}
              </Button>
            </BaseButtonsForm.Item>
          }
          onFinish={onFinish}
        >
          <BaseButtonsForm.Item
            name="headCoach"
            label={t('trainings.headCoach')}
            style={{ width: '100%' }}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Select options={staffData?.staffAll} style={{ width: '100%' }} />
          </BaseButtonsForm.Item>
          <BaseButtonsForm.Item name="coaches" label={t('trainings.coaches')}>
            <Select mode="multiple" allowClear options={staffData?.staffAll} style={{ width: '100%' }} />
          </BaseButtonsForm.Item>
        </BaseButtonsForm>
        {colItems}
        <Divider orientation="left">Новички</Divider>
        {leadItems}
      </Row>
      <Row gutter={16}>
        <Form form={formNovice} name="novice_form" style={{ width: '100%' }} layout="inline" onFinish={onNoviceFinish}>
          <Col span={16}>
            <Form.Item name="novices" style={{ width: '100%' }}>
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Введите всех, кто был на пробной тренировке"
                options={teamLeadsData ? teamLeadsData.leadsForTeam! : []}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={
                    formNovice.getFieldValue('novices')
                      ? formNovice.getFieldValue('novices').length == 0
                      : true ||
                        !formNovice.isFieldsTouched(true) ||
                        !!formNovice.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  {t('common.submit')}
                </Button>
              )}
            </Form.Item>
          </Col>
        </Form>
      </Row>
    </>
  );
};
