import dayjs, { Dayjs } from 'rc-picker/node_modules/dayjs';
import LocalizedFormat from 'rc-picker/node_modules/dayjs/plugin/localizedFormat';
import localeData from 'rc-picker/node_modules/dayjs/plugin/localeData';
import isBetween from 'rc-picker/node_modules/dayjs/plugin/isBetween';
import updateLocale from 'rc-picker/node_modules/dayjs/plugin/updateLocale';
import weekday from 'rc-picker/node_modules/dayjs/plugin/weekday';
import relativeTime from 'rc-picker/node_modules/dayjs/plugin/relativeTime';
import dayOfYear from 'rc-picker/node_modules/dayjs/plugin/dayOfYear';
import customParseFormat from 'rc-picker/node_modules/dayjs/plugin/customParseFormat';
// import 'dayjs/locale/en';

// dayjs.locale('ru');
dayjs.extend(LocalizedFormat);
dayjs.extend(localeData);
dayjs.extend(isBetween);
dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(relativeTime);
dayjs.extend(dayOfYear)
dayjs.extend(customParseFormat)

dayjs.updateLocale('en', {
  weekStart: 1,
  weekdays: 'Воскресенье_Понедельник_Вторник_Среда_Четверг_Пятница_Суббота'.split('_'), // weekdays Array
  weekdaysMin: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
  months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split('_'),
});
export type AppDate = Dayjs;

export class Dates {
  static setLocale(locale: string): void {
    dayjs.locale(locale);
  }

  static getToday(): AppDate {
    return dayjs();
  }

  static getClearDate(): AppDate {
    return this.getToday().hour(0).minute(0).second(0).millisecond(0);
  }

  static getMonths(): string[] {
    return dayjs.months();
  }

  static getDays(): string[] {
    return dayjs.weekdaysShort();
  }

  static getDate(date: number | string): AppDate {
    return dayjs(date);
  }

  static format(date: AppDate | string | number, query: string): string {
    if (typeof date === 'string' || typeof date === 'number') {
      return dayjs(date).format(query);
    } else {
      return date.format(query);
    }
  }
}
