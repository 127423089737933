import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StaffDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type StaffDeleteMutation = { __typename?: 'Mutation', staffDelete: { __typename?: 'StaffPayload', recordId: string, record: { __typename?: 'Staff', birthday?: any | null, department: Types.Department, description?: string | null, fileName?: string | null, id: string, name: string, orderNumber: number, phone?: string | null, published: boolean, userId: string, work: string, user: { __typename?: 'User', firstName: string, id: string, lastName: string, phone: string, roles: Array<Types.Role> } }, errors?: Array<{ __typename?: 'StaffDoesNotExistsProblem', message: string } | { __typename?: 'StaffExistInOtherComponentsProblem', component: string, message: string } | { __typename?: 'StaffNameExistsProblem', message: string } | { __typename?: 'StaffSaveProblem', message: string } | { __typename?: 'StaffUpdateProblem', message: string }> | null } };

export type StaffPublishUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type StaffPublishUpdateMutation = { __typename?: 'Mutation', staffPublishUpdate: { __typename?: 'StaffPayload', recordId: string, record: { __typename?: 'Staff', birthday?: any | null, department: Types.Department, description?: string | null, fileName?: string | null, id: string, name: string, orderNumber: number, phone?: string | null, published: boolean, userId: string, work: string, user: { __typename?: 'User', firstName: string, id: string, lastName: string, phone: string, roles: Array<Types.Role> } }, errors?: Array<{ __typename?: 'StaffDoesNotExistsProblem', message: string } | { __typename?: 'StaffExistInOtherComponentsProblem', component: string, message: string } | { __typename?: 'StaffNameExistsProblem', message: string } | { __typename?: 'StaffSaveProblem', message: string } | { __typename?: 'StaffUpdateProblem', message: string }> | null } };

export type StaffSaveMutationVariables = Types.Exact<{
  staffInput: Types.StaffInput;
}>;


export type StaffSaveMutation = { __typename?: 'Mutation', staffSave: { __typename?: 'StaffPayload', recordId: string, record: { __typename?: 'Staff', birthday?: any | null, department: Types.Department, description?: string | null, fileName?: string | null, id: string, name: string, orderNumber: number, phone?: string | null, published: boolean, userId: string, work: string, user: { __typename?: 'User', firstName: string, id: string, lastName: string, phone: string, roles: Array<Types.Role> } }, errors?: Array<{ __typename?: 'StaffDoesNotExistsProblem', message: string } | { __typename?: 'StaffExistInOtherComponentsProblem', component: string, message: string } | { __typename?: 'StaffNameExistsProblem', message: string } | { __typename?: 'StaffSaveProblem', message: string } | { __typename?: 'StaffUpdateProblem', message: string }> | null } };

export type StaffUpdateMutationVariables = Types.Exact<{
  staffInput: Types.StaffInputWithId;
}>;


export type StaffUpdateMutation = { __typename?: 'Mutation', staffUpdate: { __typename?: 'StaffPayload', recordId: string, record: { __typename?: 'Staff', birthday?: any | null, department: Types.Department, description?: string | null, fileName?: string | null, id: string, name: string, orderNumber: number, phone?: string | null, published: boolean, userId: string, work: string, user: { __typename?: 'User', firstName: string, id: string, lastName: string, phone: string, roles: Array<Types.Role> } }, errors?: Array<{ __typename?: 'StaffDoesNotExistsProblem', message: string } | { __typename?: 'StaffExistInOtherComponentsProblem', component: string, message: string } | { __typename?: 'StaffNameExistsProblem', message: string } | { __typename?: 'StaffSaveProblem', message: string } | { __typename?: 'StaffUpdateProblem', message: string }> | null } };

export type DepartmentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DepartmentsQuery = { __typename?: 'Query', departments?: Array<Types.Department> | null };

export type StaffQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['ID']>;
  before?: Types.InputMaybe<Types.Scalars['ID']>;
  filter?: Types.InputMaybe<Types.StaffFilter>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type StaffQuery = { __typename?: 'Query', staff?: { __typename?: 'StaffConnection', edges: Array<{ __typename?: 'StaffEdge', cursor: string, node?: { __typename?: 'Staff', birthday?: any | null, department: Types.Department, description?: string | null, fileName?: string | null, id: string, name: string, orderNumber: number, phone?: string | null, published: boolean, userId: string, work: string, user: { __typename?: 'User', firstName: string, id: string, lastName: string, phone: string, roles: Array<Types.Role> } } | null }>, pageInfo?: { __typename?: 'PageInfo', endCursor: string, hasNextPage?: boolean | null, startCursor: string } | null } | null };

export type StaffAllQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StaffAllQuery = { __typename?: 'Query', staffAll?: Array<{ __typename?: 'StaffDto', value: string, label: string }> | null };

export type StaffPersonQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type StaffPersonQuery = { __typename?: 'Query', staffPerson?: { __typename?: 'Staff', birthday?: any | null, department: Types.Department, description?: string | null, fileName?: string | null, id: string, name: string, orderNumber: number, phone?: string | null, published: boolean, userId: string, work: string, user: { __typename?: 'User', firstName: string, id: string, lastName: string, phone: string, roles: Array<Types.Role> } } | null };


export const StaffDeleteDocument = gql`
    mutation staffDelete($id: ID!) {
  staffDelete(id: $id) {
    record {
      birthday
      department
      description
      fileName
      id
      name
      orderNumber
      phone
      published
      user {
        firstName
        id
        lastName
        phone
        roles
      }
      userId
      work
    }
    recordId
    errors {
      ... on StaffDoesNotExistsProblem {
        message
      }
      ... on StaffExistInOtherComponentsProblem {
        component
        message
      }
      ... on StaffNameExistsProblem {
        message
      }
      ... on StaffSaveProblem {
        message
      }
      ... on StaffUpdateProblem {
        message
      }
    }
  }
}
    `;
export type StaffDeleteMutationFn = Apollo.MutationFunction<StaffDeleteMutation, StaffDeleteMutationVariables>;

/**
 * __useStaffDeleteMutation__
 *
 * To run a mutation, you first call `useStaffDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffDeleteMutation, { data, loading, error }] = useStaffDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStaffDeleteMutation(baseOptions?: Apollo.MutationHookOptions<StaffDeleteMutation, StaffDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StaffDeleteMutation, StaffDeleteMutationVariables>(StaffDeleteDocument, options);
      }
export type StaffDeleteMutationHookResult = ReturnType<typeof useStaffDeleteMutation>;
export type StaffDeleteMutationResult = Apollo.MutationResult<StaffDeleteMutation>;
export type StaffDeleteMutationOptions = Apollo.BaseMutationOptions<StaffDeleteMutation, StaffDeleteMutationVariables>;
export const StaffPublishUpdateDocument = gql`
    mutation staffPublishUpdate($id: ID!) {
  staffPublishUpdate(id: $id) {
    record {
      birthday
      department
      description
      fileName
      id
      name
      orderNumber
      phone
      published
      user {
        firstName
        id
        lastName
        phone
        roles
      }
      userId
      work
    }
    recordId
    errors {
      ... on StaffDoesNotExistsProblem {
        message
      }
      ... on StaffExistInOtherComponentsProblem {
        component
        message
      }
      ... on StaffNameExistsProblem {
        message
      }
      ... on StaffSaveProblem {
        message
      }
      ... on StaffUpdateProblem {
        message
      }
    }
  }
}
    `;
export type StaffPublishUpdateMutationFn = Apollo.MutationFunction<StaffPublishUpdateMutation, StaffPublishUpdateMutationVariables>;

/**
 * __useStaffPublishUpdateMutation__
 *
 * To run a mutation, you first call `useStaffPublishUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffPublishUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffPublishUpdateMutation, { data, loading, error }] = useStaffPublishUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStaffPublishUpdateMutation(baseOptions?: Apollo.MutationHookOptions<StaffPublishUpdateMutation, StaffPublishUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StaffPublishUpdateMutation, StaffPublishUpdateMutationVariables>(StaffPublishUpdateDocument, options);
      }
export type StaffPublishUpdateMutationHookResult = ReturnType<typeof useStaffPublishUpdateMutation>;
export type StaffPublishUpdateMutationResult = Apollo.MutationResult<StaffPublishUpdateMutation>;
export type StaffPublishUpdateMutationOptions = Apollo.BaseMutationOptions<StaffPublishUpdateMutation, StaffPublishUpdateMutationVariables>;
export const StaffSaveDocument = gql`
    mutation staffSave($staffInput: StaffInput!) {
  staffSave(staffInput: $staffInput) {
    record {
      birthday
      department
      description
      fileName
      id
      name
      orderNumber
      phone
      published
      user {
        firstName
        id
        lastName
        phone
        roles
      }
      userId
      work
    }
    recordId
    errors {
      ... on StaffDoesNotExistsProblem {
        message
      }
      ... on StaffExistInOtherComponentsProblem {
        component
        message
      }
      ... on StaffNameExistsProblem {
        message
      }
      ... on StaffSaveProblem {
        message
      }
      ... on StaffUpdateProblem {
        message
      }
    }
  }
}
    `;
export type StaffSaveMutationFn = Apollo.MutationFunction<StaffSaveMutation, StaffSaveMutationVariables>;

/**
 * __useStaffSaveMutation__
 *
 * To run a mutation, you first call `useStaffSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffSaveMutation, { data, loading, error }] = useStaffSaveMutation({
 *   variables: {
 *      staffInput: // value for 'staffInput'
 *   },
 * });
 */
export function useStaffSaveMutation(baseOptions?: Apollo.MutationHookOptions<StaffSaveMutation, StaffSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StaffSaveMutation, StaffSaveMutationVariables>(StaffSaveDocument, options);
      }
export type StaffSaveMutationHookResult = ReturnType<typeof useStaffSaveMutation>;
export type StaffSaveMutationResult = Apollo.MutationResult<StaffSaveMutation>;
export type StaffSaveMutationOptions = Apollo.BaseMutationOptions<StaffSaveMutation, StaffSaveMutationVariables>;
export const StaffUpdateDocument = gql`
    mutation staffUpdate($staffInput: StaffInputWithId!) {
  staffUpdate(staffInput: $staffInput) {
    record {
      birthday
      department
      description
      fileName
      id
      name
      orderNumber
      phone
      published
      user {
        firstName
        id
        lastName
        phone
        roles
      }
      userId
      work
    }
    recordId
    errors {
      ... on StaffDoesNotExistsProblem {
        message
      }
      ... on StaffExistInOtherComponentsProblem {
        component
        message
      }
      ... on StaffNameExistsProblem {
        message
      }
      ... on StaffSaveProblem {
        message
      }
      ... on StaffUpdateProblem {
        message
      }
    }
  }
}
    `;
export type StaffUpdateMutationFn = Apollo.MutationFunction<StaffUpdateMutation, StaffUpdateMutationVariables>;

/**
 * __useStaffUpdateMutation__
 *
 * To run a mutation, you first call `useStaffUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStaffUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [staffUpdateMutation, { data, loading, error }] = useStaffUpdateMutation({
 *   variables: {
 *      staffInput: // value for 'staffInput'
 *   },
 * });
 */
export function useStaffUpdateMutation(baseOptions?: Apollo.MutationHookOptions<StaffUpdateMutation, StaffUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StaffUpdateMutation, StaffUpdateMutationVariables>(StaffUpdateDocument, options);
      }
export type StaffUpdateMutationHookResult = ReturnType<typeof useStaffUpdateMutation>;
export type StaffUpdateMutationResult = Apollo.MutationResult<StaffUpdateMutation>;
export type StaffUpdateMutationOptions = Apollo.BaseMutationOptions<StaffUpdateMutation, StaffUpdateMutationVariables>;
export const DepartmentsDocument = gql`
    query departments {
  departments
}
    `;

/**
 * __useDepartmentsQuery__
 *
 * To run a query within a React component, call `useDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDepartmentsQuery(baseOptions?: Apollo.QueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options);
      }
export function useDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options);
        }
export type DepartmentsQueryHookResult = ReturnType<typeof useDepartmentsQuery>;
export type DepartmentsLazyQueryHookResult = ReturnType<typeof useDepartmentsLazyQuery>;
export type DepartmentsQueryResult = Apollo.QueryResult<DepartmentsQuery, DepartmentsQueryVariables>;
export function refetchDepartmentsQuery(variables?: DepartmentsQueryVariables) {
      return { query: DepartmentsDocument, variables: variables }
    }
export const StaffDocument = gql`
    query staff($after: ID, $before: ID, $filter: StaffFilter, $first: Int, $last: Int) {
  staff(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
  ) {
    edges {
      cursor
      node {
        birthday
        department
        description
        fileName
        id
        name
        orderNumber
        phone
        published
        user {
          firstName
          id
          lastName
          phone
          roles
        }
        userId
        work
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      startCursor
    }
  }
}
    `;

/**
 * __useStaffQuery__
 *
 * To run a query within a React component, call `useStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useStaffQuery(baseOptions?: Apollo.QueryHookOptions<StaffQuery, StaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
      }
export function useStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffQuery, StaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffQuery, StaffQueryVariables>(StaffDocument, options);
        }
export type StaffQueryHookResult = ReturnType<typeof useStaffQuery>;
export type StaffLazyQueryHookResult = ReturnType<typeof useStaffLazyQuery>;
export type StaffQueryResult = Apollo.QueryResult<StaffQuery, StaffQueryVariables>;
export function refetchStaffQuery(variables?: StaffQueryVariables) {
      return { query: StaffDocument, variables: variables }
    }
export const StaffAllDocument = gql`
    query staffAll {
  staffAll {
    value
    label
  }
}
    `;

/**
 * __useStaffAllQuery__
 *
 * To run a query within a React component, call `useStaffAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffAllQuery(baseOptions?: Apollo.QueryHookOptions<StaffAllQuery, StaffAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffAllQuery, StaffAllQueryVariables>(StaffAllDocument, options);
      }
export function useStaffAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffAllQuery, StaffAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffAllQuery, StaffAllQueryVariables>(StaffAllDocument, options);
        }
export type StaffAllQueryHookResult = ReturnType<typeof useStaffAllQuery>;
export type StaffAllLazyQueryHookResult = ReturnType<typeof useStaffAllLazyQuery>;
export type StaffAllQueryResult = Apollo.QueryResult<StaffAllQuery, StaffAllQueryVariables>;
export function refetchStaffAllQuery(variables?: StaffAllQueryVariables) {
      return { query: StaffAllDocument, variables: variables }
    }
export const StaffPersonDocument = gql`
    query staffPerson($id: ID!) {
  staffPerson(id: $id) {
    birthday
    department
    description
    fileName
    id
    name
    orderNumber
    phone
    published
    user {
      firstName
      id
      lastName
      phone
      roles
    }
    userId
    work
  }
}
    `;

/**
 * __useStaffPersonQuery__
 *
 * To run a query within a React component, call `useStaffPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffPersonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStaffPersonQuery(baseOptions: Apollo.QueryHookOptions<StaffPersonQuery, StaffPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffPersonQuery, StaffPersonQueryVariables>(StaffPersonDocument, options);
      }
export function useStaffPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffPersonQuery, StaffPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffPersonQuery, StaffPersonQueryVariables>(StaffPersonDocument, options);
        }
export type StaffPersonQueryHookResult = ReturnType<typeof useStaffPersonQuery>;
export type StaffPersonLazyQueryHookResult = ReturnType<typeof useStaffPersonLazyQuery>;
export type StaffPersonQueryResult = Apollo.QueryResult<StaffPersonQuery, StaffPersonQueryVariables>;
export function refetchStaffPersonQuery(variables: StaffPersonQueryVariables) {
      return { query: StaffPersonDocument, variables: variables }
    }