import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ResetPasswordRequest,
  login,
  LoginRequest,
  signUp,
  SignUpRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  NewPasswordData,
  setNewPassword,
} from '@app/api/auth.api';
import { setUser } from '@app/store/slices/userSlice';
import {
  deleteToken,
  deleteTokenValid,
  deleteUser,
  persistToken,
  persistTokenExpire,
  readToken,
} from '@app/services/localStorage.service';
import { FetchResult, QueryResult } from '@apollo/client';
import {
  IsPublishedUserQuery,
  LoginMutation,
  UserUpdatePasswordMutation,
} from '@app/components/dinamchiki/user/user.generated';
import { Exact } from '@app/components/dinamchiki/types';
import { notificationController } from '@app/controllers/notificationController';

export interface AuthSlice {
  token: string | null;
}

const initialState: AuthSlice = {
  token: readToken(),
};

export const doLogin = createAsyncThunk(
  'auth/doLogin',
  async (loginPayload: Promise<FetchResult<LoginMutation, Record<string, any>, Record<string, any>>>, { dispatch }) =>
    loginPayload.then((res) => {
      dispatch(setUser(res.data?.login.user));
      persistTokenExpire(res.data?.login.authToken.expiredAt);
      if (res.data) {
        persistToken(res.data.login.authToken.accessToken);
        return res.data.login.authToken.accessToken;
      }
      return '';
    }),
);

export const doSignUp = createAsyncThunk('auth/doSignUp', async (signUpPayload: SignUpRequest) =>
  signUp(signUpPayload),
);

export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPassPayload: ResetPasswordRequest) => resetPassword(resetPassPayload),
);

export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (getIsPublishedUser: Promise<QueryResult<IsPublishedUserQuery, Exact<{ phone: string }>>>, { dispatch }) =>
    getIsPublishedUser.then((res) => {
      return res.data?.isPublishedUser;
    }),
);

export const doSetNewPassword = createAsyncThunk(
  'auth/doSetNewPassword',
  async (
    userUpdatePassword: Promise<FetchResult<UserUpdatePasswordMutation, Record<string, any>, Record<string, any>>>,
    { dispatch },
  ) => userUpdatePassword,
);

export const doLogout = createAsyncThunk('auth/doLogout', (payload, { dispatch }) => {
  deleteToken();
  deleteTokenValid();
  deleteUser();
  dispatch(setUser(null));
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
  },
});

export default authSlice.reducer;
