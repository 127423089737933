import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import dayjs from 'dayjs';
import { readTokenValid } from '@app/services/localStorage.service';

interface RequireAuthProps {
  children: React.ReactNode;
  role: string;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children, role }) => {
  const user = useAppSelector((state) => state.user.user);
  return readTokenValid() ? (
    user?.roles.find((item) => item === role) ? (
      <>{children}</>
    ) : (
      <Navigate to="/auth/login" replace />
    )
  ) : (
    <Navigate to="/auth/login" replace />
  );
};

export default RequireAuth;
