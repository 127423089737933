import {
  useTrainingsByDayAndUserLazyQuery,
  useTrainingsByDayLazyQuery,
} from '@app/components/dinamchiki/training/training.generated';
import { TrainingCalendarItem } from '@app/components/dinamchiki/training/TrainingCalendarItem';
import { Training } from '@app/components/dinamchiki/types';
import { AppDate } from '@app/constants/Dates';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Doctor } from 'api/doctors.api';
import React, {SetStateAction, useEffect, useState} from 'react';
import { TreatmentNotFound } from './TreatmentNotFound/TreatmentNotFound';

interface TreatmentPanelProps {
  event?: AppDate;
}

export const TreatmentPanel: React.FC<TreatmentPanelProps> = ({ event }) => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [trainings, setTrainings] = useState<Training[] | undefined | null>([]);
  const [getTrainings] = useTrainingsByDayAndUserLazyQuery();
  const [getAllTrainings] = useTrainingsByDayLazyQuery();
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (user?.roles.find((item: string) => item === 'ECONOMIST')) {
      getAllTrainings({ variables: { date: event?.format('YYYY-MM-DD') } }).then((res) =>
        setTrainings(res.data?.trainingsByDay),
      );
    } else {
      getTrainings({ variables: { date: event?.format('YYYY-MM-DD') } }).then((res) =>
        setTrainings(res.data?.trainingsByDayAndUser),
      );
    }
    // getDoctorsData().then((res) => setDoctors(res));
  }, [event, getAllTrainings, getTrainings, user?.roles]);

  // const currentDoctor = doctors.find((doctor) => doctor.id === event?.doctor);

  if (event && trainings && trainings.length > 0) {
    return <TrainingCalendarItem event={event} trainings={trainings} />;
  } else {
    return <TreatmentNotFound />;
  }

  // if (event && currentDoctor) {
  //   const doctor: TreatmentDoctor = {
  //     name: currentDoctor.name,
  //     address: currentDoctor.address,
  //     date: event.date,
  //     imgUrl: currentDoctor.imgUrl,
  //     phone: currentDoctor.phone,
  //     speciality: specifities.find(({ id }) => id === currentDoctor.specifity)?.name || '',
  //   };

  //   return <TreatmentDoctor doctor={doctor} />;
  // } else {
  //   return <TreatmentNotFound />;
  // }
};
