import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoginMutationVariables = Types.Exact<{
  input: Types.LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthResponse', authToken: { __typename?: 'AuthToken', accessToken: string, expiredAt: any, refreshToken: string }, user: { __typename?: 'User', firstName: string, id: string, lastName: string, phone: string, roles: Array<Types.Role> } } };

export type RegisterMutationVariables = Types.Exact<{
  input: Types.RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'AuthResponse', authToken: { __typename?: 'AuthToken', accessToken: string, expiredAt: any, refreshToken: string }, user: { __typename?: 'User', firstName: string, id: string, lastName: string, phone: string, roles: Array<Types.Role> } } };

export type UserDeleteMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type UserDeleteMutation = { __typename?: 'Mutation', userDelete: { __typename?: 'UserPayload', recordId: string, record: { __typename?: 'User', firstName: string, id: string, lastName: string, phone: string, roles: Array<Types.Role> }, errors?: Array<{ __typename?: 'UserDoesNotExistsProblem', message: string } | { __typename?: 'UserExistInOtherComponentsProblem', component: string, message: string } | { __typename?: 'UserNameExistsProblem', message: string } | { __typename?: 'UserSaveProblem', message: string } | { __typename?: 'UserUpdateProblem', message: string }> | null } };

export type UserUpdateMutationVariables = Types.Exact<{
  userInput: Types.UserInputWithId;
}>;


export type UserUpdateMutation = { __typename?: 'Mutation', userUpdate: { __typename?: 'UserPayload', recordId: string, record: { __typename?: 'User', firstName: string, id: string, lastName: string, phone: string, roles: Array<Types.Role> }, errors?: Array<{ __typename?: 'UserDoesNotExistsProblem', message: string } | { __typename?: 'UserExistInOtherComponentsProblem', component: string, message: string } | { __typename?: 'UserNameExistsProblem', message: string } | { __typename?: 'UserSaveProblem', message: string } | { __typename?: 'UserUpdateProblem', message: string }> | null } };

export type UserUpdatePasswordMutationVariables = Types.Exact<{
  userInput: Types.UserPassword;
}>;


export type UserUpdatePasswordMutation = { __typename?: 'Mutation', userUpdatePassword: { __typename?: 'UserPayload', recordId: string, record: { __typename?: 'User', firstName: string, id: string, lastName: string, phone: string, roles: Array<Types.Role> }, errors?: Array<{ __typename?: 'UserDoesNotExistsProblem', message: string } | { __typename?: 'UserExistInOtherComponentsProblem', component: string, message: string } | { __typename?: 'UserNameExistsProblem', message: string } | { __typename?: 'UserSaveProblem', message: string } | { __typename?: 'UserUpdateProblem', message: string }> | null } };

export type RolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', roles?: Array<Types.Role> | null };

export type UserQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', firstName: string, id: string, lastName: string, phone: string, roles: Array<Types.Role> } };

export type UserAllQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserAllQuery = { __typename?: 'Query', userAll?: Array<{ __typename?: 'UserDto', value: string, label: string }> | null };

export type UsersQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['ID']>;
  before?: Types.InputMaybe<Types.Scalars['ID']>;
  filter?: Types.InputMaybe<Types.UserFilter>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UsersQuery = { __typename?: 'Query', users?: { __typename?: 'UserConnection', edges: Array<{ __typename?: 'UserEdge', cursor: string, node?: { __typename?: 'User', firstName: string, id: string, lastName: string, phone: string, roles: Array<Types.Role> } | null }>, pageInfo?: { __typename?: 'PageInfo', endCursor: string, hasNextPage?: boolean | null, startCursor: string } | null } | null };

export type IsPublishedUserQueryVariables = Types.Exact<{
  phone: Types.Scalars['String'];
}>;


export type IsPublishedUserQuery = { __typename?: 'Query', isPublishedUser: boolean };


export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    authToken {
      accessToken
      expiredAt
      refreshToken
    }
    user {
      firstName
      id
      lastName
      phone
      roles
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RegisterDocument = gql`
    mutation register($input: RegisterInput!) {
  register(input: $input) {
    authToken {
      accessToken
      expiredAt
      refreshToken
    }
    user {
      firstName
      id
      lastName
      phone
      roles
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const UserDeleteDocument = gql`
    mutation userDelete($id: ID!) {
  userDelete(id: $id) {
    record {
      firstName
      id
      lastName
      phone
      roles
    }
    recordId
    errors {
      ... on UserDoesNotExistsProblem {
        message
      }
      ... on UserExistInOtherComponentsProblem {
        component
        message
      }
      ... on UserNameExistsProblem {
        message
      }
      ... on UserSaveProblem {
        message
      }
      ... on UserUpdateProblem {
        message
      }
    }
  }
}
    `;
export type UserDeleteMutationFn = Apollo.MutationFunction<UserDeleteMutation, UserDeleteMutationVariables>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDeleteMutation(baseOptions?: Apollo.MutationHookOptions<UserDeleteMutation, UserDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(UserDeleteDocument, options);
      }
export type UserDeleteMutationHookResult = ReturnType<typeof useUserDeleteMutation>;
export type UserDeleteMutationResult = Apollo.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<UserDeleteMutation, UserDeleteMutationVariables>;
export const UserUpdateDocument = gql`
    mutation userUpdate($userInput: UserInputWithId!) {
  userUpdate(userInput: $userInput) {
    record {
      firstName
      id
      lastName
      phone
      roles
    }
    recordId
    errors {
      ... on UserDoesNotExistsProblem {
        message
      }
      ... on UserExistInOtherComponentsProblem {
        component
        message
      }
      ... on UserNameExistsProblem {
        message
      }
      ... on UserSaveProblem {
        message
      }
      ... on UserUpdateProblem {
        message
      }
    }
  }
}
    `;
export type UserUpdateMutationFn = Apollo.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, options);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const UserUpdatePasswordDocument = gql`
    mutation userUpdatePassword($userInput: UserPassword!) {
  userUpdatePassword(userInput: $userInput) {
    record {
      firstName
      id
      lastName
      phone
      roles
    }
    recordId
    errors {
      ... on UserDoesNotExistsProblem {
        message
      }
      ... on UserExistInOtherComponentsProblem {
        component
        message
      }
      ... on UserNameExistsProblem {
        message
      }
      ... on UserSaveProblem {
        message
      }
      ... on UserUpdateProblem {
        message
      }
    }
  }
}
    `;
export type UserUpdatePasswordMutationFn = Apollo.MutationFunction<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>;

/**
 * __useUserUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUserUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdatePasswordMutation, { data, loading, error }] = useUserUpdatePasswordMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUserUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>(UserUpdatePasswordDocument, options);
      }
export type UserUpdatePasswordMutationHookResult = ReturnType<typeof useUserUpdatePasswordMutation>;
export type UserUpdatePasswordMutationResult = Apollo.MutationResult<UserUpdatePasswordMutation>;
export type UserUpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UserUpdatePasswordMutation, UserUpdatePasswordMutationVariables>;
export const RolesDocument = gql`
    query roles {
  roles
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export function refetchRolesQuery(variables?: RolesQueryVariables) {
      return { query: RolesDocument, variables: variables }
    }
export const UserDocument = gql`
    query user($id: ID!) {
  user(id: $id) {
    firstName
    id
    lastName
    phone
    roles
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export function refetchUserQuery(variables: UserQueryVariables) {
      return { query: UserDocument, variables: variables }
    }
export const UserAllDocument = gql`
    query userAll {
  userAll {
    value
    label
  }
}
    `;

/**
 * __useUserAllQuery__
 *
 * To run a query within a React component, call `useUserAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAllQuery(baseOptions?: Apollo.QueryHookOptions<UserAllQuery, UserAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAllQuery, UserAllQueryVariables>(UserAllDocument, options);
      }
export function useUserAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAllQuery, UserAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAllQuery, UserAllQueryVariables>(UserAllDocument, options);
        }
export type UserAllQueryHookResult = ReturnType<typeof useUserAllQuery>;
export type UserAllLazyQueryHookResult = ReturnType<typeof useUserAllLazyQuery>;
export type UserAllQueryResult = Apollo.QueryResult<UserAllQuery, UserAllQueryVariables>;
export function refetchUserAllQuery(variables?: UserAllQueryVariables) {
      return { query: UserAllDocument, variables: variables }
    }
export const UsersDocument = gql`
    query users($after: ID, $before: ID, $filter: UserFilter, $first: Int, $last: Int) {
  users(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
  ) {
    edges {
      cursor
      node {
        firstName
        id
        lastName
        phone
        roles
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      startCursor
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export function refetchUsersQuery(variables?: UsersQueryVariables) {
      return { query: UsersDocument, variables: variables }
    }
export const IsPublishedUserDocument = gql`
    query isPublishedUser($phone: String!) {
  isPublishedUser(phone: $phone)
}
    `;

/**
 * __useIsPublishedUserQuery__
 *
 * To run a query within a React component, call `useIsPublishedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPublishedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPublishedUserQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useIsPublishedUserQuery(baseOptions: Apollo.QueryHookOptions<IsPublishedUserQuery, IsPublishedUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsPublishedUserQuery, IsPublishedUserQueryVariables>(IsPublishedUserDocument, options);
      }
export function useIsPublishedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsPublishedUserQuery, IsPublishedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsPublishedUserQuery, IsPublishedUserQueryVariables>(IsPublishedUserDocument, options);
        }
export type IsPublishedUserQueryHookResult = ReturnType<typeof useIsPublishedUserQuery>;
export type IsPublishedUserLazyQueryHookResult = ReturnType<typeof useIsPublishedUserLazyQuery>;
export type IsPublishedUserQueryResult = Apollo.QueryResult<IsPublishedUserQuery, IsPublishedUserQueryVariables>;
export function refetchIsPublishedUserQuery(variables: IsPublishedUserQueryVariables) {
      return { query: IsPublishedUserDocument, variables: variables }
    }