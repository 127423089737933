import { Panel } from '@app/components/common/Collapse/Collapse';
import { AppDate } from '@app/constants/Dates';
import * as S from '@app/pages/uiComponentsPages/UIComponentsPage.styles';
import dayjs from 'rc-picker/node_modules/dayjs';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { StudentVisitPanel } from '../studentVisit/StudentVisitPanel';
import { Training } from '../types';
import { TrainingPanel } from './TrainingPanel';

export interface TrainingItems {
  trainings: Training[];
  event: AppDate;
}

export const TrainingCalendarItem: React.FC<TrainingItems> = ({ event, trainings }) => {
  const { t } = useTranslation();

  return (
    <>
      <S.Card title={t('trainings.titleWithDate', { date: dayjs(event).format('DD/MM') })}>
        <S.CollapseWrapper defaultActiveKey={[trainings[0].id]}>
          {trainings.map((item) => (
            <Panel header={<TrainingPanel training={item} />} key={item.id}>
              <StudentVisitPanel training={item} />
            </Panel>
          ))}
        </S.CollapseWrapper>
      </S.Card>
    </>
  );
};
