import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useLoginMutation } from '@app/components/dinamchiki/user/user.generated';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doLogin } from '@app/store/slices/authSlice';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as S from './LoginForm.styles';

interface LoginFormData {
  phone: string;
  password: string;
  rememberMe: boolean;
}

export const initValues: LoginFormData = {
  phone: '',
  password: '',
  rememberMe: false,
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [login] = useLoginMutation();
  const location = useLocation();
  //   {
  //   onCompleted: ({ login }) => {
  //     // persistToken(login.authToken.accessToken);
  //     navigate('/');
  //   },
  // }
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: LoginFormData) => {
    setLoading(true);

    dispatch(
      doLogin(
        login({
          variables: { input: { phone: values.phone, password: values.password, remember: values.rememberMe } },
        }),
      ),
    )
      .then(() => navigate('/'))
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional">
        <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
        <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
        <Auth.FormItem
          name="phone"
          initialValue={location.state ? location.state.phone : ''}
          label={t('common.phone')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              pattern: new RegExp(/9[ -0123456789]+$/i),
              message: t('common.isMobileNumber'),
            },
          ]}
        >
          <Auth.FormInput addonBefore="+7" />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" initialValue={false} valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
