import {StudentVisit, Training, VisitStatus} from "@app/components/dinamchiki/types";
import * as S from './StudentVisitPanel.styles';
import {Col, Divider, Popover, Row, Space, Tooltip} from 'antd';
import {DeleteTwoTone, GiftOutlined, InfoCircleOutlined, PhoneOutlined} from '@ant-design/icons';
import dayjs from 'rc-picker/node_modules/dayjs';
import {Button} from '@app/components/common/buttons/Button/Button';
import {Badge} from "@app/components/common/Badge/Badge";
import {
    StudentVisitsByTrainingDocument,
    useStudentVisitPublishUpdateMutation, useStudentVisitsByTrainingLazyQuery
} from "@app/components/dinamchiki/studentVisit/studentVisit.generated";
import {notificationController} from "@app/controllers/notificationController";
import {useStudentPublishUpdateMutation} from "@app/components/dinamchiki/student/student.generated";
import {useEffect, useState} from "react";
import {useAppSelector} from "@app/hooks/reduxHooks";

export interface StudentVisitItemProps {
    item: StudentVisit
    refetchHandler: (value: boolean) => void;
}
export const StudentVisitItem = (props: StudentVisitItemProps) => {
    const {
        item,
        refetchHandler
    } = props
    const [studentPublishUpdateMutation] = useStudentPublishUpdateMutation();
    const [studentVisitPublishUpdateMutation] = useStudentVisitPublishUpdateMutation({ errorPolicy: 'all' });

    const onClick = (): void => {
        studentVisitPublishUpdateMutation({
            variables: { id: item.id },
        }).then((res) => {
            if (res.errors) {
                res.errors?.map((it) => {
                    notificationController.error({ message: it.message });
                });
            } else {
                refetchHandler(true);
            }
        });
    };

    const delStudentVisits = () => {
        studentPublishUpdateMutation({
            variables: { id: item.student.id },
        }).then((res) => {
            if (res.errors) {
                res.errors?.map((item) => {
                    notificationController.error({ message: item.message });
                });
            } else {
                refetchHandler(true);
            }
        });
    }

    return (
        <Col key={item.id} span={24}>
            <S.ScreeningsRow justify={'space-between'} $isActive={true} wrap={false}>
                <Col span={24}>
                    <Row gutter={[10, 0]} align="middle" wrap={false}>
                        {/* <Col>
                  <S.Avatar
                    shape="square"
                    src={item.student.fileName}
                    alt="Friend avatar"
                    $isPrimary={item.visitStatus === VisitStatus.Visited}
                    $isSecondary={item.payed}
                  />
                </Col> */}
                        <Col span={2}>
                            {
                                item.student.birthday &&
                                dayjs(item.student.birthday, 'YYYY-MM-DDTHH:mm:ssZ[Z]').dayOfYear() >
                                dayjs().subtract(3, 'day').dayOfYear()
                                && dayjs(item.student.birthday, 'YYYY-MM-DDTHH:mm:ssZ[Z]').dayOfYear() <
                                dayjs().add(3, 'day').dayOfYear()
                                    ?
                                    <Popover title="День рожденья" content={
                                        dayjs(item.student.birthday).format('DD/MM')
                                    }
                                    >
                                        <GiftOutlined />
                                    </Popover>
                                    : ''
                            }
                        </Col>
                        <Col span={2}>
                            <Popover content={
                                <>
                                    <Space wrap>
                                        {
                                            item.student.lastVisits?.length &&
                                            item.student.lastVisits.map((item, index) => <Badge key={index} count={item} />)
                                        }
                                    </Space>
                                    <Divider>Телефоны родителей</Divider>
                                    <Space wrap>
                                        {item.student.creators?.map((creator) => (
                                                <Button type="primary" href={`tel:+7${creator.phone}`} key={creator.id} icon={<PhoneOutlined />} size="small">
                                                    {creator.name}
                                                </Button>
                                            )
                                        )}
                                    </Space>
                                </>
                            } title="Последние посещения">
                                <InfoCircleOutlined/>
                            </Popover>
                        </Col>
                        <Col span={18} onClick={() => onClick()}>
                            <S.Name
                                $isPrimary={item.visitStatus === VisitStatus.Visited}
                                $isSecondary={!item.payed && item.student.published}
                                $isWarning={!item.payed && !item.student.published}
                            >
                                {item.student.name}
                            </S.Name>
                        </Col>
                        <Col span={2}>
                            { item.student.published && (
                                (item.student.lastVisits?.length && dayjs().diff(dayjs(item.training?.time), 'day') > 0 &&
                                    dayjs(item.training?.time).diff(dayjs(item.student.lastVisits[0], 'DD/MM'), 'day') > 30) ||
                                item.student.lastVisits?.length === 0
                            )
                                    ?
                                    <Popover content={
                                        <Space wrap>
                                            <Button
                                                type="dashed"
                                                danger
                                                icon={<DeleteTwoTone twoToneColor="#eb2f96" />}
                                                size="middle"
                                                onClick={() => delStudentVisits()}
                                            >
                                                Удалить
                                            </Button>
                                        </Space>
                                    } title="Удаляем?">
                                        <DeleteTwoTone twoToneColor="#eb2f96"/>
                                    </Popover>
                                    : ''
                            }
                        </Col>
                    </Row>
                </Col>
            </S.ScreeningsRow>
        </Col>
    );
};
