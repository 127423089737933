import React from 'react';
import * as S from './References.styles';
import { SlSocialVkontakte, SlSocialInstagram } from 'react-icons/sl';
import { TbBrandTelegram } from 'react-icons/tb';
import { SiGooglechrome } from 'react-icons/si';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text>
        Создано{' '}
        <a href="https://www.dinamchiki.ru" target="_blank" rel="noreferrer">
          ДФК Динамчики{' '}
        </a>
        в 2023 &copy;.
      </S.Text>
      <S.Icons>
        <a href="https://linkedin.com/company/altence" target="_blank" rel="noreferrer">
          <SiGooglechrome />
        </a>
        <a href="https://vk.com/dinamchiki" target="_blank" rel="noreferrer">
          <SlSocialVkontakte />
        </a>
        <a href="https://t.me/dinamchiki" target="_blank" rel="noreferrer">
          <TbBrandTelegram />
        </a>
        <a href="https://www.instagram.com/dinamchiki" target="_blank" rel="noreferrer">
          <SlSocialInstagram />
        </a>
      </S.Icons>
    </S.ReferencesWrapper>
  );
};
