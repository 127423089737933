import React, { useEffect, useState } from 'react';
import { BellOutlined } from '@ant-design/icons';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Badge } from '@app/components/common/Badge/Badge';
import { NotificationsOverlay } from '@app/components/header/components/notificationsDropdown/NotificationsOverlay/NotificationsOverlay';
import { notifications as fetchedNotifications, Notification } from '@app/api/notifications.api';
import { HeaderActionWrapper } from '@app/components/header/Header.styles';
import { useNearestHeadCoachStudentBirthdaysQuery } from '@app/components/dinamchiki/student/student.generated';
import dayjs from 'rc-picker/node_modules/dayjs';

export const NotificationsDropdown: React.FC = () => {
  const [isOpened, setOpened] = useState(false);
  const { data, loading, error } = useNearestHeadCoachStudentBirthdaysQuery({ variables: { before: 7, after: 7 } });
  const [notifications, setNotifications] = useState<Notification[]>([]);
  useEffect(() => {
    if (!loading && data) {
      const birthdaysNotifications = data.nearestHeadCoachStudentBirthdays?.map((item) => {
        const birthday = dayjs(item.birthday, 'YYYY-MM-DDTHH:mm:ssZ[Z]');
        const years = dayjs().from(birthday, true);
        return {
          id: '4',
          userName: item.teams && item.teams[0].place ? `${item.name} / ${item.teams[0].place.name}` : item.name,
          userIcon: '',
          place: item.teams ? item.teams[0].name : '',
          description: `${birthday.format('DD/MM')}, ${years}`,
        };
      });
      setNotifications(birthdaysNotifications ? birthdaysNotifications : []);
    }
  }, [data, loading]);
  if (notifications.length > 0) {
    return (
      <Dropdown
        trigger={['click']}
        overlay={<NotificationsOverlay notifications={notifications} setNotifications={setNotifications} />}
        onOpenChange={setOpened}
      >
        <HeaderActionWrapper>
          <Button
            type={isOpened ? 'ghost' : 'text'}
            icon={
              <Badge dot>
                <BellOutlined />
              </Badge>
            }
          />
        </HeaderActionWrapper>
      </Dropdown>
    );
  } else {
    return <></>;
  }
};
