import {ArrowLeftOutlined, SearchOutlined} from '@ant-design/icons';
import { useTrainingsByMonthLazyQuery } from '@app/components/dinamchiki/training/training.generated';
import { Scalars } from '@app/components/dinamchiki/types';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Col, Row } from 'antd';
import { AppDate, Dates } from 'constants/Dates';
import { useResponsive } from 'hooks/useResponsive';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '../../common/buttons/Button/Button';
import { DashboardCard } from '../DashboardCard/DashboardCard';
import { TreatmentCalendar } from './TreatmentCalendar/TreatmentCalendar';
import { TreatmentPanel } from './TreatmentPanel';

export interface TrainingEvent {
  coachIds?: string[] | undefined | null;
  id: string;
  published: boolean;
  stadiumId?: string | null | undefined;
  teamId: string;
  time?: Scalars['Time'];
  visits: number;
}

export const TreatmentCard: React.FC = () => {
  const { isTablet } = useResponsive();

  const [selectedDate, setDate] = useState<AppDate>(Dates.getToday());
  const [isDateClicked, setDateClicked] = useState(true);
  const [calendar, setCalendar] = useState<TrainingEvent[] | undefined | null>([]);

  const user = useAppSelector((state) => state.user.user);
  const [getCalendar, result] = useTrainingsByMonthLazyQuery();

  useEffect(() => {
    // user && getUserCalendar(user?.id).then((res) => setCalendar(res));
  }, [user]);

  useEffect(() => {
    getCalendar({ variables: { date: selectedDate.format('YYYY-MM-DD') } }).then((res) =>
      setCalendar(res.data?.trainingsByMonth),
    );
  }, [selectedDate]);

  const { i18n, t } = useTranslation();

  useEffect(() => {
    setDate((selectedDate) => selectedDate.locale(i18n.language));
  }, [i18n.language]);

  const handleDecreaseMonth = () => {
    setDate(selectedDate.month(selectedDate.month() - 1));
  };

  const handleIncreaseMonth = () => {
    setDate(selectedDate.month(selectedDate.month() + 1));
  };

  const handleToday = () => {
    setDate(Dates.getToday());
  };

  const calendarItem = (
    <TreatmentCalendar
      calendar={calendar}
      date={selectedDate}
      setDate={setDate}
      onDecrease={handleDecreaseMonth}
      onIncrease={handleIncreaseMonth}
      onToday={handleToday}
      setDateClicked={setDateClicked}
    />
  );

  const panelItem = <TreatmentPanel event={selectedDate} />;

  return (
    <DashboardCard title={t('medical-dashboard.treatmentPlan.title')}>
      <RowStyled gutter={[10, 10]} wrap={false}>
        {isTablet ? (
          <>
            <Col md={8}>{calendarItem}</Col>
            <Col md={16}>{panelItem}</Col>
          </>
        ) : isDateClicked && calendar?.some((event) => Dates.getDate(event.time).isSame(selectedDate, 'date')) ? (
          <BackButtonWrapper span={24}>
            {panelItem}

            <BackButton type="text" icon={<ArrowLeftOutlined />} onClick={() => setDateClicked(false)} >
                к календарю
            </BackButton>
          </BackButtonWrapper>
        ) : (
          <Col span={24}>{calendarItem}</Col>
        )}
      </RowStyled>
    </DashboardCard>
  );
};

const BackButtonWrapper = styled(Col)`
  position: relative;
`;

const BackButton = styled(Button)`
  position: absolute;
  top: -30px;
  left: 0;
  color: var(--white);
`;

const RowStyled = styled(Row)`
  min-height: 21.75rem;
`;
