import React from 'react';
import {
  CompassOutlined,
  DashboardOutlined,
  FormOutlined,
  HomeOutlined,
  LayoutOutlined,
  LineChartOutlined,
  TableOutlined,
  UserOutlined,
  BlockOutlined,
} from '@ant-design/icons';
import { MdOutlinePlace } from 'react-icons/md';
import { GiSoccerField, GiSoccerKick, GiReceiveMoney, GiPayMoney, GiTakeMyMoney, GiPiggyBank } from 'react-icons/gi';
import { BsKeyboard, BsCalendar2Date } from 'react-icons/bs';
import { FaUserTie, FaChild, FaMoneyBillAlt } from 'react-icons/fa';
import { MdFamilyRestroom } from 'react-icons/md';
import { RiTeamLine } from 'react-icons/ri';
import { BiCheckSquare } from 'react-icons/bi';
import { MdChildFriendly } from 'react-icons/md';
import { ReactComponent as NftIcon } from '@app/assets/icons/nft-icon.svg';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  role: string;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'places.title',
    key: 'places',
    url: '/places',
    icon: <MdOutlinePlace />,
    role: 'ADMIN',
  },
  {
    title: 'stadiums.title',
    key: 'stadiums',
    url: '/stadiums',
    icon: <GiSoccerField />,
    role: 'ADMIN',
  },
  {
    title: 'users.title',
    key: 'users',
    url: '/users',
    icon: <BsKeyboard />,
    role: 'ADMIN',
  },
  {
    title: 'staff.title',
    key: 'staff',
    url: '/staff',
    icon: <FaUserTie />,
    role: 'ADMIN',
  },
  {
    title: 'creators.title',
    key: 'creators',
    url: '/creators',
    icon: <MdFamilyRestroom />,
    role: 'ADMIN',
  },
  {
    title: 'teams.title',
    key: 'teams',
    url: '/teams',
    icon: <RiTeamLine />,
    role: 'ADMIN',
  },
  {
    title: 'students.title',
    key: 'students',
    url: '/students',
    icon: <FaChild />,
    role: 'ADMIN',
  },
  {
    title: 'studentVisits.title',
    key: 'studentVisits',
    url: '/',
    icon: <BiCheckSquare />,
    role: 'COACH',
  },
  {
    title: 'trainings.title',
    key: 'trainings',
    url: '/trainings',
    icon: <GiSoccerKick />,
    role: 'EDITOR',
  },
  {
    title: 'trainingDays.title',
    key: 'trainingDays',
    url: '/trainings/days',
    icon: <BsCalendar2Date />,
    role: 'EDITOR',
  },
  {
    title: 'leads.title',
    key: 'leads',
    url: '/leads',
    icon: <MdChildFriendly />,
    role: 'ADMIN',
  },
  {
    title: 'money.moveTitle',
    key: 'moneyMoves',
    url: '/money/moves',
    icon: <GiReceiveMoney />,
    role: 'DIRECTOR',
  },
  {
    title: 'money.costTitle',
    key: 'moneyCosts',
    url: '/money/costs',
    icon: <GiPiggyBank />,
    role: 'DIRECTOR',
  },
  {
    title: 'coachPayments.myTitle',
    key: 'coachPaymentsMy',
    url: '/coach/payments/my',
    icon: <FaMoneyBillAlt />,
    role: 'COACH',
  },
  {
    title: 'coachPayments.allTitle',
    key: 'coachPaymentsAll',
    url: '/coach/payments/all',
    icon: <GiPayMoney />,
    role: 'ADMIN',
  },
  {
    title: 'rentPaymentByTrainings.title',
    key: 'rentPaymentByTrainings',
    url: '/rent/trainings',
    icon: <GiTakeMyMoney />,
    role: 'ADMIN',
  },
  {
    title: 'common.nft-dashboard',
    key: 'nft-dashboard',
    // TODO use path variable
    url: '/nft',
    icon: <NftIcon />,
    role: 'ADMIN',
  },
  {
    title: 'common.medical-dashboard',
    key: 'medical-dashboard',
    url: '/medical-dashboard',
    icon: <DashboardOutlined />,
    role: 'ADMIN',
  },
  {
    title: 'common.apps',
    key: 'apps',
    icon: <HomeOutlined />,
    role: 'ADMIN',
    children: [
      {
        title: 'common.feed',
        key: 'feed',
        url: '/apps/feed',
        role: 'ADMIN',
      },
      {
        title: 'common.kanban',
        key: 'kanban',
        url: '/apps/kanban',
        role: 'ADMIN',
      },
    ],
  },
  {
    title: 'common.authPages',
    key: 'auth',
    icon: <UserOutlined />,
    role: 'ADMIN',
    children: [
      {
        title: 'common.login',
        key: 'login',
        url: '/auth/login',
        role: 'ADMIN',
      },
      {
        title: 'common.signUp',
        key: 'singUp',
        url: '/auth/sign-up',
        role: 'ADMIN',
      },
      {
        title: 'common.lock',
        key: 'lock',
        url: '/auth/lock',
        role: 'ADMIN',
      },
      {
        title: 'common.forgotPass',
        key: 'forgotPass',
        url: '/auth/forgot-password',
        role: 'ADMIN',
      },
      {
        title: 'common.securityCode',
        key: 'securityCode',
        url: '/auth/security-code',
        role: 'ADMIN',
      },
      {
        title: 'common.newPassword',
        key: 'newPass',
        url: '/auth/new-password',
        role: 'ADMIN',
      },
    ],
  },
  {
    title: 'common.forms',
    key: 'forms',
    icon: <FormOutlined />,
    role: 'ADMIN',
    children: [
      {
        title: 'common.advancedForms',
        key: 'advanced-forms',
        role: 'ADMIN',
        url: '/forms/advanced-forms',
      },
    ],
  },
  {
    title: 'common.dataTables',
    key: 'dataTables',
    url: '/data-tables',
    icon: <TableOutlined />,
    role: 'ADMIN',
  },
  {
    title: 'common.charts',
    key: 'charts',
    url: '/charts',
    icon: <LineChartOutlined />,
    role: 'ADMIN',
  },
  {
    title: 'common.maps',
    key: 'maps',
    icon: <CompassOutlined />,
    role: 'ADMIN',
    children: [
      {
        title: 'common.googleMap',
        key: 'google-maps',
        url: '/maps/google-maps',
        role: 'ADMIN',
      },
      {
        title: 'common.leafletMap',
        key: 'leaflet-maps',
        url: '/maps/leaflet-maps',
        role: 'ADMIN',
      },
      {
        title: 'common.reactSimpleMaps',
        key: 'react-simple-maps',
        url: '/maps/react-simple-maps',
        role: 'ADMIN',
      },
      {
        title: 'common.pigeonMaps',
        key: 'pigeon-maps',
        url: '/maps/pigeon-maps',
        role: 'ADMIN',
      },
    ],
  },
  {
    title: 'common.pages',
    key: 'pages',
    icon: <LayoutOutlined />,
    role: 'ADMIN',
    children: [
      {
        title: 'common.profilePage',
        key: 'profile',
        url: '/profile',
        role: 'ADMIN',
      },
      {
        title: 'common.serverError',
        key: 'serverError',
        url: '/server-error',
        role: 'ADMIN',
      },
      {
        title: 'common.clientError',
        key: '404Error',
        url: '/404',
        role: 'ADMIN',
      },
    ],
  },
  {
    title: 'common.ui',
    key: 'ui',
    icon: <BlockOutlined />,
    role: 'ADMIN',
    children: [
      {
        title: 'common.alert',
        key: 'alert',
        url: '/ui-components/alert',
        role: 'ADMIN',
      },
      {
        title: 'common.avatar',
        key: 'avatar',
        url: '/ui-components/avatar',
        role: 'ADMIN',
      },
      {
        title: 'common.autocomplete',
        key: 'auto-complete',
        url: '/ui-components/auto-complete',
        role: 'ADMIN',
      },
      {
        title: 'common.badge',
        key: 'badge',
        url: '/ui-components/badge',
        role: 'ADMIN',
      },
      {
        title: 'common.breadcrumbs',
        key: 'breadcrumbs',
        url: '/ui-components/breadcrumbs',
        role: 'ADMIN',
      },
      {
        title: 'common.button',
        key: 'button',
        url: '/ui-components/button',
        role: 'ADMIN',
      },
      {
        title: 'common.checkbox',
        key: 'checkbox',
        url: '/ui-components/checkbox',
        role: 'ADMIN',
      },
      {
        title: 'common.collapse',
        key: 'collapse',
        url: '/ui-components/collapse',
        role: 'ADMIN',
      },
      {
        title: 'common.dateTimePicker',
        key: 'dateTimePicker',
        url: '/ui-components/date-time-picker',
        role: 'ADMIN',
      },
      {
        title: 'common.dropdown',
        key: 'dropdown',
        url: '/ui-components/dropdown',
        role: 'ADMIN',
      },
      {
        title: 'common.input',
        key: 'input',
        url: '/ui-components/input',
        role: 'ADMIN',
      },
      {
        title: 'common.modal',
        key: 'modal',
        url: '/ui-components/modal',
        role: 'ADMIN',
      },
      {
        title: 'common.notification',
        key: 'notification',
        url: '/ui-components/notification',
        role: 'ADMIN',
      },
      {
        title: 'common.pagination',
        key: 'pagination',
        url: '/ui-components/pagination',
        role: 'ADMIN',
      },
      {
        title: 'common.popconfirm',
        key: 'popconfirm',
        url: '/ui-components/popconfirm',
        role: 'ADMIN',
      },
      {
        title: 'common.popover',
        key: 'popover',
        url: '/ui-components/popover',
        role: 'ADMIN',
      },
      {
        title: 'common.progress',
        key: 'progress',
        url: '/ui-components/progress',
        role: 'ADMIN',
      },
      {
        title: 'common.radio',
        key: 'radio',
        url: '/ui-components/radio',
        role: 'ADMIN',
      },
      {
        title: 'common.rate',
        key: 'rate',
        url: '/ui-components/rate',
        role: 'ADMIN',
      },
      {
        title: 'common.result',
        key: 'result',
        url: '/ui-components/result',
        role: 'ADMIN',
      },
      {
        title: 'common.select',
        key: 'select',
        url: '/ui-components/select',
        role: 'ADMIN',
      },
      {
        title: 'common.skeleton',
        key: 'skeleton',
        url: '/ui-components/skeleton',
        role: 'ADMIN',
      },
      {
        title: 'common.spinner',
        key: 'spinner',
        url: '/ui-components/spinner',
        role: 'ADMIN',
      },
      {
        title: 'common.steps',
        key: 'steps',
        url: '/ui-components/steps',
        role: 'ADMIN',
      },
      {
        title: 'common.switch',
        key: 'switch',
        url: '/ui-components/switch',
        role: 'ADMIN',
      },
      {
        title: 'common.tabs',
        key: 'tabs',
        url: '/ui-components/tabs',
        role: 'ADMIN',
      },
      {
        title: 'common.upload',
        key: 'upload',
        url: '/ui-components/upload',
        role: 'ADMIN',
      },
    ],
  },
];
