import dayjs from 'rc-picker/node_modules/dayjs';
import React from 'react';
import { AiOutlineTeam } from 'react-icons/ai';
import { RiTimerLine } from 'react-icons/ri';
import { Training } from '../types';
import * as N from './ArticleCard.styles';

interface TrainingPanelProps {
  training: Training;
}

export const TrainingPanel: React.FC<TrainingPanelProps> = ({ training }) => {
  return (
    <N.Wrapper className="article-card">
      <N.Header>
        <N.AuthorWrapper>
          {training.team && <N.Author>{training.team.name}</N.Author>}
          <N.DateTime>
            <RiTimerLine /> {dayjs(training.time).format('HH:mm')}
          </N.DateTime>
          <N.DateTime> {training.stadium?.name}</N.DateTime>
        </N.AuthorWrapper>
        <N.StadiumWrapper>
          <N.DateTime style={{ color: '#3f8600' }}>
            {training.visits}
            <AiOutlineTeam />
          </N.DateTime>
        </N.StadiumWrapper>
      </N.Header>
    </N.Wrapper>
  );
};
