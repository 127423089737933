import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import { Image, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { VerificationCodeInput } from '@app/components/common/VerificationCodeInput/VerificationCodeInput';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doVerifySecurityCode } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SecurityCodeForm.styles';
import { useIsPublishedUserLazyQuery } from '@app/components/dinamchiki/user/user.generated';

interface SecurityCodeFormProps {
  onBack?: () => void;
  onFinish?: () => void;
}

export const SecurityCodeForm: React.FC<SecurityCodeFormProps> = ({ onBack, onFinish }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navForward = () => navigate('/auth/new-password', { state: { phone: securityCode.slice(1, 11) } });
  const navLogin = () => navigate('/auth/login', { state: { phone: securityCode.slice(1, 11) } });
  const navigateBack = useCallback(() => navigate(-1), [navigate]);
  const navigateForward = useCallback(() => navigate('/auth/new-password'), [navigate]);
  const navigateLogin = useCallback(() => navigate('/auth/login'), [navigate]);
  const [getIsPublishedUser, { data, refetch, called }] = useIsPublishedUserLazyQuery();
  const [securityCode, setSecurityCode] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isNavigated, setNavigated] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (securityCode.length === 11) {
      setLoading(true);
      const published = dispatch(
        doVerifySecurityCode(getIsPublishedUser({ variables: { phone: securityCode.slice(1, 11) } })),
      )
        // dispatch(doVerifySecurityCode({ code: securityCode }))
        .unwrap()
        .then((res) => setNavigated(res))
        .then(onFinish)
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  }, [securityCode, navigateForward, navigateLogin, onFinish, dispatch]);

  const nav = useEffect(() => {
    if (isNavigated) return navLogin;
    else return navForward;
  }, [isNavigated]);

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <Auth.BackWrapper onClick={onBack || navigateBack}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <S.ContentWrapper>
          <S.ImageWrapper>
            <Image src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>
          <Auth.FormTitle>{t('securityCodeForm.title')}</Auth.FormTitle>
          <S.VerifyEmailDescription>{t('common.verifCodeSent')}</S.VerifyEmailDescription>
          {isLoading ? <Spin /> : <VerificationCodeInput length={11} autoFocus onChange={setSecurityCode} />}
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
