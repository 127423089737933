export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  HourMinute: any;
  Time: any;
};

export enum Age {
  Preschool = 'PRESCHOOL',
  School = 'SCHOOL'
}

export type Article = {
  __typename?: 'Article';
  author: User;
  authorId: Scalars['ID'];
  description: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  published: Scalars['Boolean'];
  tags?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export type ArticleConnection = {
  __typename?: 'ArticleConnection';
  edges: Array<ArticleEdge>;
  pageInfo: PageInfo;
};

export type ArticleDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'ArticleDoesNotExistsProblem';
  message: Scalars['String'];
};

export type ArticleEdge = {
  __typename?: 'ArticleEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Article>;
};

export type ArticleFilter = {
  title?: InputMaybe<Scalars['String']>;
};

export type ArticleInput = {
  description: Scalars['String'];
  fileName?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
  tags?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export type ArticleInputWithId = {
  id: Scalars['ID'];
  input: ArticleInput;
};

export type ArticlePayload = {
  __typename?: 'ArticlePayload';
  errors?: Maybe<Array<ArticleProblems>>;
  record: Article;
  recordId: Scalars['ID'];
};

export type ArticleProblems = ArticleDoesNotExistsProblem | ArticleSaveProblem | ArticleUpdateProblem;

export type ArticleSaveProblem = ProblemInterface & {
  __typename?: 'ArticleSaveProblem';
  message: Scalars['String'];
};

export type ArticleUpdateProblem = ProblemInterface & {
  __typename?: 'ArticleUpdateProblem';
  message: Scalars['String'];
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  authToken: AuthToken;
  user: User;
};

export type AuthToken = {
  __typename?: 'AuthToken';
  accessToken: Scalars['String'];
  expiredAt: Scalars['Time'];
  refreshToken: Scalars['String'];
};

export type Cart = {
  __typename?: 'Cart';
  id: Scalars['ID'];
  kitIds: Array<Scalars['ID']>;
  kits: Array<Kit>;
  published: Scalars['Boolean'];
  student: Student;
  studentId: Scalars['ID'];
  sum: Scalars['Int'];
};

export type CartConnection = {
  __typename?: 'CartConnection';
  edges: Array<CartEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CartDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'CartDoesNotExistsProblem';
  message: Scalars['String'];
};

export type CartDto = {
  __typename?: 'CartDto';
  label: Scalars['String'];
  value: Scalars['ID'];
};

export type CartEdge = {
  __typename?: 'CartEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Cart>;
};

export type CartExistInOtherComponentsProblem = ProblemInterface & {
  __typename?: 'CartExistInOtherComponentsProblem';
  component: Scalars['String'];
  message: Scalars['String'];
};

export type CartFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type CartInput = {
  kitIds: Array<Scalars['ID']>;
  published: Scalars['Boolean'];
  studentId: Scalars['ID'];
  sum: Scalars['Int'];
};

export type CartInputWithId = {
  id: Scalars['ID'];
  input: CartInput;
};

export type CartPayload = {
  __typename?: 'CartPayload';
  errors?: Maybe<Array<CartProblems>>;
  record: Cart;
  recordId: Scalars['ID'];
};

export type CartProblems = CartDoesNotExistsProblem | CartExistInOtherComponentsProblem | CartSaveProblem | CartUpdateProblem;

export type CartSaveProblem = ProblemInterface & {
  __typename?: 'CartSaveProblem';
  message: Scalars['String'];
};

export type CartUpdateProblem = ProblemInterface & {
  __typename?: 'CartUpdateProblem';
  message: Scalars['String'];
};

export type CastingItemInput = {
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type ClubBalance = {
  __typename?: 'ClubBalance';
  date: Scalars['Time'];
  id: Scalars['ID'];
  otherCosts: Scalars['Int'];
  published: Scalars['Boolean'];
  rent: Scalars['Int'];
  salary: Scalars['Int'];
  sum: Scalars['Int'];
  tickets: Scalars['Int'];
};

export type ClubBalanceConnection = {
  __typename?: 'ClubBalanceConnection';
  edges: Array<ClubBalanceEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ClubBalanceDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'ClubBalanceDoesNotExistsProblem';
  message: Scalars['String'];
};

export type ClubBalanceEdge = {
  __typename?: 'ClubBalanceEdge';
  cursor: Scalars['ID'];
  node?: Maybe<ClubBalance>;
};

export type ClubBalanceFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type ClubBalanceInput = {
  date: Scalars['Time'];
  otherCosts: Scalars['Int'];
  published: Scalars['Boolean'];
  rent: Scalars['Int'];
  salary: Scalars['Int'];
  tickets: Scalars['Int'];
};

export type ClubBalanceInputWithId = {
  id: Scalars['ID'];
  input: ClubBalanceInput;
};

export type ClubBalancePayload = {
  __typename?: 'ClubBalancePayload';
  errors?: Maybe<Array<ClubBalanceProblems>>;
  record: ClubBalance;
  recordId: Scalars['ID'];
};

export type ClubBalanceProblems = ClubBalanceDoesNotExistsProblem | ClubBalanceSaveProblem | ClubBalanceUpdateProblem;

export type ClubBalanceSaveProblem = ProblemInterface & {
  __typename?: 'ClubBalanceSaveProblem';
  message: Scalars['String'];
};

export type ClubBalanceUpdateProblem = ProblemInterface & {
  __typename?: 'ClubBalanceUpdateProblem';
  message: Scalars['String'];
};

export type CoachPaymentByMonth = {
  __typename?: 'CoachPaymentByMonth';
  coach: Staff;
  coachId: Scalars['ID'];
  date: Scalars['Time'];
  id: Scalars['ID'];
  published: Scalars['Boolean'];
  sum: Scalars['Int'];
};

export type CoachPaymentByMonthConnection = {
  __typename?: 'CoachPaymentByMonthConnection';
  edges: Array<CoachPaymentByMonthEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CoachPaymentByMonthDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'CoachPaymentByMonthDoesNotExistsProblem';
  message: Scalars['String'];
};

export type CoachPaymentByMonthEdge = {
  __typename?: 'CoachPaymentByMonthEdge';
  cursor: Scalars['ID'];
  node?: Maybe<CoachPaymentByMonth>;
};

export type CoachPaymentByMonthFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type CoachPaymentByMonthInput = {
  coachId: Scalars['ID'];
  date: Scalars['Time'];
  published: Scalars['Boolean'];
  sum: Scalars['Int'];
};

export type CoachPaymentByMonthInputWithId = {
  id: Scalars['ID'];
  input: CoachPaymentByMonthInput;
};

export type CoachPaymentByMonthPayload = {
  __typename?: 'CoachPaymentByMonthPayload';
  errors?: Maybe<Array<CoachPaymentByMonthProblems>>;
  record: CoachPaymentByMonth;
  recordId: Scalars['ID'];
};

export type CoachPaymentByMonthProblems = CoachPaymentByMonthDoesNotExistsProblem | CoachPaymentByMonthSaveProblem | CoachPaymentByMonthUpdateProblem;

export type CoachPaymentByMonthSaveProblem = ProblemInterface & {
  __typename?: 'CoachPaymentByMonthSaveProblem';
  message: Scalars['String'];
};

export type CoachPaymentByMonthUpdateProblem = ProblemInterface & {
  __typename?: 'CoachPaymentByMonthUpdateProblem';
  message: Scalars['String'];
};

export type CoachPaymentByTeam = {
  __typename?: 'CoachPaymentByTeam';
  coach: Staff;
  coachId: Scalars['ID'];
  dateFinish: Scalars['Time'];
  dateStart: Scalars['Time'];
  id: Scalars['ID'];
  paymentRule?: Maybe<CoachPaymentRule>;
  published: Scalars['Boolean'];
  sum?: Maybe<Scalars['Int']>;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']>;
};

export type CoachPaymentByTeamConnection = {
  __typename?: 'CoachPaymentByTeamConnection';
  edges: Array<CoachPaymentByTeamEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CoachPaymentByTeamDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'CoachPaymentByTeamDoesNotExistsProblem';
  message: Scalars['String'];
};

export type CoachPaymentByTeamEdge = {
  __typename?: 'CoachPaymentByTeamEdge';
  cursor: Scalars['ID'];
  node?: Maybe<CoachPaymentByTeam>;
};

export type CoachPaymentByTeamFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type CoachPaymentByTeamInput = {
  coachId: Scalars['ID'];
  dateFinish: Scalars['Time'];
  dateStart: Scalars['Time'];
  paymentRule: CoachPaymentRule;
  published: Scalars['Boolean'];
  sum: Scalars['Int'];
  teamID: Scalars['ID'];
};

export type CoachPaymentByTeamInputWithId = {
  id: Scalars['ID'];
  input: CoachPaymentByTeamInput;
};

export type CoachPaymentByTeamPayload = {
  __typename?: 'CoachPaymentByTeamPayload';
  errors?: Maybe<Array<CoachPaymentByTeamProblems>>;
  record: CoachPaymentByTeam;
  recordId: Scalars['ID'];
};

export type CoachPaymentByTeamProblems = CoachPaymentByTeamDoesNotExistsProblem | CoachPaymentByTeamSaveProblem | CoachPaymentByTeamUpdateProblem;

export type CoachPaymentByTeamSaveProblem = ProblemInterface & {
  __typename?: 'CoachPaymentByTeamSaveProblem';
  message: Scalars['String'];
};

export type CoachPaymentByTeamUpdateProblem = ProblemInterface & {
  __typename?: 'CoachPaymentByTeamUpdateProblem';
  message: Scalars['String'];
};

export type CoachPaymentByTraining = {
  __typename?: 'CoachPaymentByTraining';
  coach: Staff;
  coachId: Scalars['ID'];
  id: Scalars['ID'];
  isHeadCoach: Scalars['Boolean'];
  sum?: Maybe<Scalars['Int']>;
  training?: Maybe<Training>;
  trainingId?: Maybe<Scalars['ID']>;
};

export type CoachPaymentByTrainingConnection = {
  __typename?: 'CoachPaymentByTrainingConnection';
  edges: Array<CoachPaymentByTrainingEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CoachPaymentByTrainingDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'CoachPaymentByTrainingDoesNotExistsProblem';
  message: Scalars['String'];
};

export type CoachPaymentByTrainingEdge = {
  __typename?: 'CoachPaymentByTrainingEdge';
  cursor: Scalars['ID'];
  node?: Maybe<CoachPaymentByTraining>;
};

export type CoachPaymentByTrainingFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type CoachPaymentByTrainingInput = {
  coachId: Scalars['ID'];
  isHeadCoach: Scalars['Boolean'];
  sum: Scalars['Int'];
  trainingID: Scalars['ID'];
};

export type CoachPaymentByTrainingInputWithId = {
  id: Scalars['ID'];
  input: CoachPaymentByTrainingInput;
};

export type CoachPaymentByTrainingPayload = {
  __typename?: 'CoachPaymentByTrainingPayload';
  errors?: Maybe<Array<CoachPaymentByTrainingProblems>>;
  record: CoachPaymentByTraining;
  recordId: Scalars['ID'];
};

export type CoachPaymentByTrainingProblems = CoachPaymentByTrainingDoesNotExistsProblem | CoachPaymentByTrainingSaveProblem | CoachPaymentByTrainingUpdateProblem;

export type CoachPaymentByTrainingSaveProblem = ProblemInterface & {
  __typename?: 'CoachPaymentByTrainingSaveProblem';
  message: Scalars['String'];
};

export type CoachPaymentByTrainingUpdateProblem = ProblemInterface & {
  __typename?: 'CoachPaymentByTrainingUpdateProblem';
  message: Scalars['String'];
};

export enum CoachPaymentRule {
  ByTeam = 'BY_TEAM',
  ByTraining = 'BY_TRAINING'
}

export type Creator = {
  __typename?: 'Creator';
  cardDetails?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  published: Scalars['Boolean'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type CreatorConnection = {
  __typename?: 'CreatorConnection';
  edges: Array<CreatorEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type CreatorDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'CreatorDoesNotExistsProblem';
  message: Scalars['String'];
};

export type CreatorDto = {
  __typename?: 'CreatorDto';
  label: Scalars['String'];
  value: Scalars['ID'];
};

export type CreatorEdge = {
  __typename?: 'CreatorEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Creator>;
};

export type CreatorExistInOtherComponentsProblem = ProblemInterface & {
  __typename?: 'CreatorExistInOtherComponentsProblem';
  component: Scalars['String'];
  message: Scalars['String'];
};

export type CreatorFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type CreatorInput = {
  cardDetails?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  published: Scalars['Boolean'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type CreatorInputWithId = {
  id: Scalars['ID'];
  input: CreatorInput;
};

export type CreatorPayload = {
  __typename?: 'CreatorPayload';
  errors?: Maybe<Array<CreatorProblems>>;
  record: Creator;
  recordId: Scalars['ID'];
};

export type CreatorProblems = CreatorDoesNotExistsProblem | CreatorExistInOtherComponentsProblem | CreatorSaveProblem | CreatorUpdateProblem;

export type CreatorSaveProblem = ProblemInterface & {
  __typename?: 'CreatorSaveProblem';
  message: Scalars['String'];
};

export type CreatorUpdateProblem = ProblemInterface & {
  __typename?: 'CreatorUpdateProblem';
  message: Scalars['String'];
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum Department {
  Coach = 'COACH',
  Head = 'HEAD'
}

export type Kit = {
  __typename?: 'Kit';
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  number?: Maybe<Scalars['Int']>;
  price: Scalars['Int'];
  published: Scalars['Boolean'];
  quantity?: Maybe<Scalars['Int']>;
  size: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type KitConnection = {
  __typename?: 'KitConnection';
  edges: Array<KitEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type KitDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'KitDoesNotExistsProblem';
  message: Scalars['String'];
};

export type KitDto = {
  __typename?: 'KitDto';
  label: Scalars['String'];
  value: Scalars['ID'];
};

export type KitEdge = {
  __typename?: 'KitEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Kit>;
};

export type KitExistInOtherComponentsProblem = ProblemInterface & {
  __typename?: 'KitExistInOtherComponentsProblem';
  component: Scalars['String'];
  message: Scalars['String'];
};

export type KitFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type KitInput = {
  fileName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  number?: InputMaybe<Scalars['Int']>;
  price: Scalars['Int'];
  published: Scalars['Boolean'];
  quantity?: InputMaybe<Scalars['Int']>;
  size: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type KitInputWithId = {
  id: Scalars['ID'];
  input: KitInput;
};

export type KitNameExistsProblem = ProblemInterface & {
  __typename?: 'KitNameExistsProblem';
  message: Scalars['String'];
};

export type KitPayload = {
  __typename?: 'KitPayload';
  errors?: Maybe<Array<KitProblems>>;
  record: Kit;
  recordId: Scalars['ID'];
};

export type KitProblems = KitDoesNotExistsProblem | KitExistInOtherComponentsProblem | KitNameExistsProblem | KitSaveProblem | KitUpdateProblem;

export type KitSaveProblem = ProblemInterface & {
  __typename?: 'KitSaveProblem';
  message: Scalars['String'];
};

export type KitUpdateProblem = ProblemInterface & {
  __typename?: 'KitUpdateProblem';
  message: Scalars['String'];
};

export type Lead = {
  __typename?: 'Lead';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  nextVisit?: Maybe<Training>;
  nextVisitId?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  source?: Maybe<LeadSource>;
  staff?: Maybe<Staff>;
  staffId?: Maybe<Scalars['ID']>;
  status?: Maybe<LeadStatus>;
  student?: Maybe<Student>;
  studentId?: Maybe<Scalars['ID']>;
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['ID']>;
  yearBorn?: Maybe<Scalars['Int']>;
};

export type LeadConnection = {
  __typename?: 'LeadConnection';
  edges: Array<LeadEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type LeadDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'LeadDoesNotExistsProblem';
  message: Scalars['String'];
};

export type LeadDto = {
  __typename?: 'LeadDto';
  label: Scalars['String'];
  value: Scalars['ID'];
};

export type LeadEdge = {
  __typename?: 'LeadEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Lead>;
};

export type LeadExistInOtherComponentsProblem = ProblemInterface & {
  __typename?: 'LeadExistInOtherComponentsProblem';
  component: Scalars['String'];
  message: Scalars['String'];
};

export type LeadFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type LeadInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nextVisitId?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  source?: InputMaybe<LeadSource>;
  staffId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<LeadStatus>;
  studentId?: InputMaybe<Scalars['ID']>;
  teamId?: InputMaybe<Scalars['ID']>;
  yearBorn?: InputMaybe<Scalars['Int']>;
};

export type LeadInputWithId = {
  id: Scalars['ID'];
  input: LeadInput;
};

export type LeadPayload = {
  __typename?: 'LeadPayload';
  errors?: Maybe<Array<LeadProblems>>;
  record: Lead;
  recordId: Scalars['ID'];
};

export type LeadProblems = LeadDoesNotExistsProblem | LeadExistInOtherComponentsProblem | LeadSaveProblem | LeadUpdateProblem;

export type LeadSaveProblem = ProblemInterface & {
  __typename?: 'LeadSaveProblem';
  message: Scalars['String'];
};

export enum LeadSource {
  Brother = 'BROTHER',
  Friends = 'FRIENDS',
  Gis = 'GIS',
  Google = 'GOOGLE',
  Instagram = 'INSTAGRAM',
  Lift = 'LIFT',
  OtherParents = 'OTHER_PARENTS',
  Paper = 'PAPER',
  Returned = 'RETURNED',
  Telegram = 'TELEGRAM',
  Vkontakte = 'VKONTAKTE',
  Yandex = 'YANDEX',
  YandexMap = 'YANDEX_MAP'
}

export enum LeadStatus {
  ApproveFirstTraining = 'APPROVE_FIRST_TRAINING',
  CancelAfterApproveFirstTraining = 'CANCEL_AFTER_APPROVE_FIRST_TRAINING',
  CancelAfterFirstTraining = 'CANCEL_AFTER_FIRST_TRAINING',
  CancelAfterWrite = 'CANCEL_AFTER_WRITE',
  Contracted = 'CONTRACTED',
  FirstTraining = 'FIRST_TRAINING',
  FirstTrainingAdd = 'FIRST_TRAINING_ADD',
  WantContract = 'WANT_CONTRACT',
  Write = 'WRITE'
}

export type LeadUpdateProblem = ProblemInterface & {
  __typename?: 'LeadUpdateProblem';
  message: Scalars['String'];
};

export type LeadVisitedFirstTrainingInput = {
  leadId: Scalars['ID'];
  trainingId: Scalars['ID'];
};

export type LoginInput = {
  password: Scalars['String'];
  phone: Scalars['String'];
  remember: Scalars['Boolean'];
};

export type MoneyCoach = {
  __typename?: 'MoneyCoach';
  credit: Scalars['Int'];
  date: Scalars['Time'];
  debet: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['ID'];
  moneyForm: MoneyForm;
  paymentSum: Scalars['Int'];
  published: Scalars['Boolean'];
  saldo: Scalars['Int'];
  staff?: Maybe<Staff>;
  staffId: Scalars['ID'];
};

export type MoneyCoachConnection = {
  __typename?: 'MoneyCoachConnection';
  edges: Array<MoneyCoachEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MoneyCoachDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'MoneyCoachDoesNotExistsProblem';
  message: Scalars['String'];
};

export type MoneyCoachEdge = {
  __typename?: 'MoneyCoachEdge';
  cursor: Scalars['ID'];
  node?: Maybe<MoneyCoach>;
};

export type MoneyCoachFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type MoneyCoachInput = {
  description: Scalars['String'];
  moneyForm: MoneyForm;
  paymentSum: Scalars['Int'];
  published: Scalars['Boolean'];
  staffId: Scalars['ID'];
};

export type MoneyCoachInputWithId = {
  id: Scalars['ID'];
  input: MoneyCoachInput;
};

export type MoneyCoachPayload = {
  __typename?: 'MoneyCoachPayload';
  errors?: Maybe<Array<MoneyCoachProblems>>;
  record: MoneyCoach;
  recordId: Scalars['ID'];
};

export type MoneyCoachProblems = MoneyCoachDoesNotExistsProblem | MoneyCoachSaveProblem | MoneyCoachUpdateProblem;

export type MoneyCoachSaveProblem = ProblemInterface & {
  __typename?: 'MoneyCoachSaveProblem';
  message: Scalars['String'];
};

export type MoneyCoachUpdateProblem = ProblemInterface & {
  __typename?: 'MoneyCoachUpdateProblem';
  message: Scalars['String'];
};

export type MoneyCost = {
  __typename?: 'MoneyCost';
  date: Scalars['Time'];
  description: Scalars['String'];
  id: Scalars['ID'];
  moneyForm: MoneyForm;
  published: Scalars['Boolean'];
  staff: Staff;
  staffId: Scalars['ID'];
  sum: Scalars['Int'];
};

export type MoneyCostConnection = {
  __typename?: 'MoneyCostConnection';
  edges: Array<MoneyCostEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MoneyCostDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'MoneyCostDoesNotExistsProblem';
  message: Scalars['String'];
};

export type MoneyCostEdge = {
  __typename?: 'MoneyCostEdge';
  cursor: Scalars['ID'];
  node?: Maybe<MoneyCost>;
};

export type MoneyCostFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type MoneyCostInput = {
  date: Scalars['Time'];
  description: Scalars['String'];
  moneyForm: MoneyForm;
  published: Scalars['Boolean'];
  staffId: Scalars['ID'];
  sum: Scalars['Int'];
};

export type MoneyCostInputWithId = {
  id: Scalars['ID'];
  input: MoneyCostInput;
};

export type MoneyCostPayload = {
  __typename?: 'MoneyCostPayload';
  errors?: Maybe<Array<MoneyCostProblems>>;
  record: MoneyCost;
  recordId: Scalars['ID'];
};

export type MoneyCostProblems = MoneyCostDoesNotExistsProblem | MoneyCostSaveProblem | MoneyCostUpdateProblem;

export type MoneyCostSaveProblem = ProblemInterface & {
  __typename?: 'MoneyCostSaveProblem';
  message: Scalars['String'];
};

export type MoneyCostUpdateProblem = ProblemInterface & {
  __typename?: 'MoneyCostUpdateProblem';
  message: Scalars['String'];
};

export enum MoneyForm {
  BankTransfer = 'BANK_TRANSFER',
  CardTransfer = 'CARD_TRANSFER',
  Cash = 'CASH'
}

export type MoneyMove = {
  __typename?: 'MoneyMove';
  dateFinish: Scalars['Time'];
  datePayment: Scalars['Time'];
  dateStart: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  moneyForm?: Maybe<MoneyForm>;
  owner: Staff;
  ownerId: Scalars['ID'];
  published: Scalars['Boolean'];
  student: Student;
  studentId: Scalars['ID'];
  sum?: Maybe<Scalars['Int']>;
  user: User;
  userId: Scalars['ID'];
};

export type MoneyMoveConnection = {
  __typename?: 'MoneyMoveConnection';
  edges: Array<MoneyMoveEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MoneyMoveDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'MoneyMoveDoesNotExistsProblem';
  message: Scalars['String'];
};

export type MoneyMoveEdge = {
  __typename?: 'MoneyMoveEdge';
  cursor: Scalars['ID'];
  node?: Maybe<MoneyMove>;
};

export type MoneyMoveFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type MoneyMoveInput = {
  dateFinish: Scalars['Time'];
  datePayment: Scalars['Time'];
  dateStart: Scalars['Time'];
  description?: InputMaybe<Scalars['String']>;
  moneyForm: MoneyForm;
  ownerId: Scalars['ID'];
  published: Scalars['Boolean'];
  studentId: Scalars['ID'];
  sum: Scalars['Int'];
  userId: Scalars['ID'];
};

export type MoneyMoveInputWithId = {
  id: Scalars['ID'];
  input: MoneyMoveInput;
};

export type MoneyMovePayload = {
  __typename?: 'MoneyMovePayload';
  errors?: Maybe<Array<MoneyMoveProblems>>;
  record: MoneyMove;
  recordId: Scalars['ID'];
};

export type MoneyMoveProblems = MoneyMoveDoesNotExistsProblem | MoneyMoveSaveProblem | MoneyMoveUpdateProblem;

export type MoneyMoveSaveProblem = ProblemInterface & {
  __typename?: 'MoneyMoveSaveProblem';
  message: Scalars['String'];
};

export type MoneyMoveStudentDateSum = {
  __typename?: 'MoneyMoveStudentDateSum';
  date: Scalars['Time'];
  studentId: Scalars['String'];
  sum: Scalars['Int'];
};

export type MoneyMoveUpdateProblem = ProblemInterface & {
  __typename?: 'MoneyMoveUpdateProblem';
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  articleDelete: ArticlePayload;
  articlePublishUpdate: ArticlePayload;
  articleSave: ArticlePayload;
  articleUpdate: ArticlePayload;
  cartDelete: CartPayload;
  cartPublishUpdate: CartPayload;
  cartSave: CartPayload;
  cartUpdate: CartPayload;
  castingSave: Scalars['String'];
  clubBalanceDelete: ClubBalancePayload;
  clubBalancePublishUpdate: ClubBalancePayload;
  clubBalanceSave: ClubBalancePayload;
  clubBalanceUpdate: ClubBalancePayload;
  coachPaymentByMonthDelete: CoachPaymentByMonthPayload;
  coachPaymentByMonthPublishUpdate: CoachPaymentByMonthPayload;
  coachPaymentByMonthSave: CoachPaymentByMonthPayload;
  coachPaymentByMonthUpdate: CoachPaymentByMonthPayload;
  coachPaymentByTeamDelete: CoachPaymentByTeamPayload;
  coachPaymentByTeamPublishUpdate: CoachPaymentByTeamPayload;
  coachPaymentByTeamSave: CoachPaymentByTeamPayload;
  coachPaymentByTeamUpdate: CoachPaymentByTeamPayload;
  coachPaymentByTrainingDelete: CoachPaymentByTrainingPayload;
  coachPaymentByTrainingSave: CoachPaymentByTrainingPayload;
  coachPaymentByTrainingUpdate: CoachPaymentByTrainingPayload;
  countRentPaymentSumForTraining?: Maybe<Array<Training>>;
  creatorDelete: CreatorPayload;
  creatorPublishUpdate: CreatorPayload;
  creatorSave: CreatorPayload;
  creatorUpdate: CreatorPayload;
  kitDelete: KitPayload;
  kitPublishUpdate: KitPayload;
  kitSave: KitPayload;
  kitUpdate: KitPayload;
  lastStudentVisitsGenerate?: Maybe<Array<Student>>;
  leadDelete: LeadPayload;
  leadPublishUpdate: LeadPayload;
  leadSave?: Maybe<LeadPayload>;
  leadUpdate: LeadPayload;
  leadVisitedFirstTraining: LeadPayload;
  login: AuthResponse;
  moneyCoachDelete: MoneyCoachPayload;
  moneyCoachPublishUpdate: MoneyCoachPayload;
  moneyCoachSave: MoneyCoachPayload;
  moneyCoachUpdate: MoneyCoachPayload;
  moneyCostDelete: MoneyCostPayload;
  moneyCostPublishUpdate: MoneyCostPayload;
  moneyCostSave: MoneyCostPayload;
  moneyCostUpdate: MoneyCostPayload;
  moneyMoveDelete: MoneyMovePayload;
  moneyMovePublishUpdate: MoneyMovePayload;
  moneyMoveSave: MoneyMovePayload;
  moneyMoveUpdate: MoneyMovePayload;
  noviceVisitedFirstTraining: LeadPayload;
  orderDelete: OrderPayload;
  orderPublishUpdate: OrderPayload;
  orderSave: OrderPayload;
  orderUpdate: OrderPayload;
  placeDelete: PlacePayload;
  placePublishUpdate: PlacePayload;
  placeSave: PlacePayload;
  placeUpdate: PlacePayload;
  register: AuthResponse;
  rentPaymentByMonthDelete: RentPaymentByMonthPayload;
  rentPaymentByMonthPublishUpdate: RentPaymentByMonthPayload;
  rentPaymentByMonthSave: RentPaymentByMonthPayload;
  rentPaymentByMonthUpdate: RentPaymentByMonthPayload;
  rentPaymentByTrainingDelete: RentPaymentByTrainingPayload;
  rentPaymentByTrainingPublishUpdate: RentPaymentByTrainingPayload;
  rentPaymentByTrainingSave: RentPaymentByTrainingPayload;
  rentPaymentByTrainingUpdate: RentPaymentByTrainingPayload;
  rentPaymentsAndTrainingRentSumGenerate?: Maybe<Array<RentPaymentByTrainingPayload>>;
  stadiumDelete: StadiumPayload;
  stadiumPublishUpdate: StadiumPayload;
  stadiumSave: StadiumPayload;
  stadiumUpdate: StadiumPayload;
  staffDelete: StaffPayload;
  staffPublishUpdate: StaffPayload;
  staffSave: StaffPayload;
  staffUpdate: StaffPayload;
  studentDelete: StudentPayload;
  studentPublishUpdate: StudentPayload;
  studentSave: StudentPayload;
  studentUpdate: StudentPayload;
  studentVisitDelete: StudentVisitPayload;
  studentVisitPublishUpdate: StudentVisitPayload;
  studentVisitSave: StudentVisitPayload;
  studentVisitStatusUpdate: StudentVisitPayload;
  studentVisitUpdate: StudentVisitPayload;
  studentVisitsGenerate?: Maybe<Array<StudentVisit>>;
  taskDelete: TaskPayload;
  taskPublishUpdate: TaskPayload;
  taskSave: TaskPayload;
  taskUpdate: TaskPayload;
  teamBalanceDelete: TeamBalancePayload;
  teamBalanceGenerate?: Maybe<Array<Scalars['Int']>>;
  teamBalancePublishUpdate: TeamBalancePayload;
  teamBalanceSave: TeamBalancePayload;
  teamBalanceUpdate: TeamBalancePayload;
  teamDelete: TeamPayload;
  teamPublishUpdate: TeamPayload;
  teamSave: TeamPayload;
  teamUpdate: TeamPayload;
  trainingDayDelete: TrainingDayPayload;
  trainingDayPublishUpdate: TrainingDayPayload;
  trainingDaySave: TrainingDayPayload;
  trainingDayUpdate: TrainingDayPayload;
  trainingDelete: TrainingPayload;
  trainingPublishUpdate: TrainingPayload;
  trainingSave: TrainingPayload;
  trainingUpdate: TrainingPayload;
  trainingsGenerate?: Maybe<Array<Training>>;
  updateLeadCancelFirstTraining: LeadPayload;
  updateTrainingCoachesAndVisits: TrainingPayload;
  userDelete: UserPayload;
  userUpdate: UserPayload;
  userUpdatePassword: UserPayload;
};


export type MutationArticleDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationArticlePublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationArticleSaveArgs = {
  articleInput: ArticleInput;
};


export type MutationArticleUpdateArgs = {
  articleInput: ArticleInputWithId;
};


export type MutationCartDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationCartPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationCartSaveArgs = {
  cartInput: CartInput;
};


export type MutationCartUpdateArgs = {
  cartInput: CartInputWithId;
};


export type MutationCastingSaveArgs = {
  input: CastingItemInput;
};


export type MutationClubBalanceDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationClubBalancePublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationClubBalanceSaveArgs = {
  clubBalanceInput: ClubBalanceInput;
};


export type MutationClubBalanceUpdateArgs = {
  clubBalanceInput: ClubBalanceInputWithId;
};


export type MutationCoachPaymentByMonthDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationCoachPaymentByMonthPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationCoachPaymentByMonthSaveArgs = {
  coachPaymentByMonthInput: CoachPaymentByMonthInput;
};


export type MutationCoachPaymentByMonthUpdateArgs = {
  coachPaymentByMonthInput: CoachPaymentByMonthInputWithId;
};


export type MutationCoachPaymentByTeamDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationCoachPaymentByTeamPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationCoachPaymentByTeamSaveArgs = {
  coachPaymentByTeamInput: CoachPaymentByTeamInput;
};


export type MutationCoachPaymentByTeamUpdateArgs = {
  coachPaymentByTeamInput: CoachPaymentByTeamInputWithId;
};


export type MutationCoachPaymentByTrainingDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationCoachPaymentByTrainingSaveArgs = {
  coachPaymentByTrainingInput: CoachPaymentByTrainingInput;
};


export type MutationCoachPaymentByTrainingUpdateArgs = {
  coachPaymentByTrainingInput: CoachPaymentByTrainingInputWithId;
};


export type MutationCountRentPaymentSumForTrainingArgs = {
  finishDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationCreatorDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationCreatorPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationCreatorSaveArgs = {
  creatorInput: CreatorInput;
};


export type MutationCreatorUpdateArgs = {
  creatorInput: CreatorInputWithId;
};


export type MutationKitDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationKitPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationKitSaveArgs = {
  kitInput: KitInput;
};


export type MutationKitUpdateArgs = {
  kitInput: KitInputWithId;
};


export type MutationLeadDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationLeadPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationLeadSaveArgs = {
  leadInput: LeadInput;
};


export type MutationLeadUpdateArgs = {
  leadInput: LeadInputWithId;
};


export type MutationLeadVisitedFirstTrainingArgs = {
  input: LeadVisitedFirstTrainingInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationMoneyCoachDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationMoneyCoachPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationMoneyCoachSaveArgs = {
  moneyCostInput: MoneyCoachInput;
};


export type MutationMoneyCoachUpdateArgs = {
  moneyCostInput: MoneyCoachInputWithId;
};


export type MutationMoneyCostDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationMoneyCostPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationMoneyCostSaveArgs = {
  moneyCostInput: MoneyCostInput;
};


export type MutationMoneyCostUpdateArgs = {
  moneyCostInput: MoneyCostInputWithId;
};


export type MutationMoneyMoveDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationMoneyMovePublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationMoneyMoveSaveArgs = {
  moneyMoveInput: MoneyMoveInput;
};


export type MutationMoneyMoveUpdateArgs = {
  moneyMoveInput: MoneyMoveInputWithId;
};


export type MutationNoviceVisitedFirstTrainingArgs = {
  input: NoviceVisitedFirstTrainingInput;
};


export type MutationOrderDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationOrderPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationOrderSaveArgs = {
  orderInput: OrderInput;
};


export type MutationOrderUpdateArgs = {
  orderInput: OrderInputWithId;
};


export type MutationPlaceDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationPlacePublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationPlaceSaveArgs = {
  placeInput: PlaceInput;
};


export type MutationPlaceUpdateArgs = {
  placeInput: PlaceInputWithId;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationRentPaymentByMonthDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationRentPaymentByMonthPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationRentPaymentByMonthSaveArgs = {
  rentPaymentInput: RentPaymentByMonthInput;
};


export type MutationRentPaymentByMonthUpdateArgs = {
  rentPaymentInput: RentPaymentByMonthInputWithId;
};


export type MutationRentPaymentByTrainingDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationRentPaymentByTrainingPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationRentPaymentByTrainingSaveArgs = {
  rentPaymentInput: RentPaymentByTrainingInput;
};


export type MutationRentPaymentByTrainingUpdateArgs = {
  rentPaymentInput: RentPaymentByTrainingInputWithId;
};


export type MutationRentPaymentsAndTrainingRentSumGenerateArgs = {
  finishDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationStadiumDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationStadiumPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationStadiumSaveArgs = {
  stadiumInput: StadiumInput;
};


export type MutationStadiumUpdateArgs = {
  stadiumInput: StadiumInputWithId;
};


export type MutationStaffDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationStaffPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationStaffSaveArgs = {
  staffInput: StaffInput;
};


export type MutationStaffUpdateArgs = {
  staffInput: StaffInputWithId;
};


export type MutationStudentDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationStudentPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationStudentSaveArgs = {
  studentInput: StudentInput;
};


export type MutationStudentUpdateArgs = {
  studentInput: StudentInputWithId;
};


export type MutationStudentVisitDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationStudentVisitPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationStudentVisitSaveArgs = {
  studentVisitInput: StudentVisitInput;
};


export type MutationStudentVisitStatusUpdateArgs = {
  id: Scalars['ID'];
  status: VisitStatus;
};


export type MutationStudentVisitUpdateArgs = {
  studentVisitInput: StudentVisitInputWithId;
};


export type MutationStudentVisitsGenerateArgs = {
  finishDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationTaskDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationTaskPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationTaskSaveArgs = {
  taskInput: TaskInput;
};


export type MutationTaskUpdateArgs = {
  taskInput: TaskInputWithId;
};


export type MutationTeamBalanceDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationTeamBalanceGenerateArgs = {
  finishDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationTeamBalancePublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationTeamBalanceSaveArgs = {
  teamBalanceInput: TeamBalanceInput;
};


export type MutationTeamBalanceUpdateArgs = {
  teamBalanceInput: TeamBalanceInputWithId;
};


export type MutationTeamDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationTeamPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationTeamSaveArgs = {
  teamInput: TeamInput;
};


export type MutationTeamUpdateArgs = {
  teamInput: TeamInputWithId;
};


export type MutationTrainingDayDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationTrainingDayPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationTrainingDaySaveArgs = {
  trainingDayInput: TrainingDayInput;
};


export type MutationTrainingDayUpdateArgs = {
  trainingDayInput: TrainingDayInputWithId;
};


export type MutationTrainingDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationTrainingPublishUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationTrainingSaveArgs = {
  trainingInput: TrainingInput;
};


export type MutationTrainingUpdateArgs = {
  trainingInput: TrainingInputWithId;
};


export type MutationTrainingsGenerateArgs = {
  finishDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationUpdateLeadCancelFirstTrainingArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateTrainingCoachesAndVisitsArgs = {
  input: TrainingCoachesAndVisitsInput;
};


export type MutationUserDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationUserUpdateArgs = {
  userInput: UserInputWithId;
};


export type MutationUserUpdatePasswordArgs = {
  userInput: UserPassword;
};

export type NoviceVisitedFirstTrainingInput = {
  name: Scalars['String'];
  trainingId: Scalars['ID'];
};

export type Order = {
  __typename?: 'Order';
  cart: Cart;
  cartId: Scalars['ID'];
  creator: Creator;
  creatorId: Scalars['ID'];
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orderStatus: OrderStatus;
  published: Scalars['Boolean'];
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  edges: Array<OrderEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type OrderDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'OrderDoesNotExistsProblem';
  message: Scalars['String'];
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Order>;
};

export type OrderFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type OrderInput = {
  cartId: Scalars['ID'];
  creatorId: Scalars['ID'];
  fileName?: InputMaybe<Scalars['String']>;
  orderStatus: OrderStatus;
  published: Scalars['Boolean'];
};

export type OrderInputWithId = {
  id: Scalars['ID'];
  input: OrderInput;
};

export type OrderPayload = {
  __typename?: 'OrderPayload';
  errors?: Maybe<Array<OrderProblems>>;
  record: Order;
  recordId: Scalars['ID'];
};

export type OrderProblems = OrderDoesNotExistsProblem | OrderSaveProblem | OrderUpdateProblem;

export type OrderSaveProblem = ProblemInterface & {
  __typename?: 'OrderSaveProblem';
  message: Scalars['String'];
};

export enum OrderStatus {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Finished = 'FINISHED',
  InDelivery = 'IN_DELIVERY',
  Payed = 'PAYED',
  ReadyToGet = 'READY_TO_GET'
}

export type OrderUpdateProblem = ProblemInterface & {
  __typename?: 'OrderUpdateProblem';
  message: Scalars['String'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['ID'];
  hasNextPage?: Maybe<Scalars['Boolean']>;
  startCursor: Scalars['ID'];
};

export type Place = {
  __typename?: 'Place';
  address: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  orderNumber: Scalars['Int'];
  published: Scalars['Boolean'];
};

export type PlaceConnection = {
  __typename?: 'PlaceConnection';
  edges: Array<PlaceEdge>;
  pageInfo: PageInfo;
};

export type PlaceDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'PlaceDoesNotExistsProblem';
  message: Scalars['String'];
};

export type PlaceDto = {
  __typename?: 'PlaceDto';
  label: Scalars['String'];
  value: Scalars['ID'];
};

export type PlaceEdge = {
  __typename?: 'PlaceEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Place>;
};

export type PlaceExistInOtherComponentsProblem = ProblemInterface & {
  __typename?: 'PlaceExistInOtherComponentsProblem';
  component: Scalars['String'];
  message: Scalars['String'];
};

export type PlaceFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type PlaceInput = {
  address: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  orderNumber: Scalars['Int'];
  published: Scalars['Boolean'];
};

export type PlaceInputWithId = {
  id: Scalars['ID'];
  input: PlaceInput;
};

export type PlaceNameExistsProblem = ProblemInterface & {
  __typename?: 'PlaceNameExistsProblem';
  message: Scalars['String'];
};

export type PlacePayload = {
  __typename?: 'PlacePayload';
  errors?: Maybe<Array<PlaceProblems>>;
  record: Place;
  recordId: Scalars['ID'];
};

export type PlaceProblems = PlaceDoesNotExistsProblem | PlaceExistInOtherComponentsProblem | PlaceNameExistsProblem | PlaceSaveProblem | PlaceUpdateProblem;

export type PlaceSaveProblem = ProblemInterface & {
  __typename?: 'PlaceSaveProblem';
  message: Scalars['String'];
};

export type PlaceUpdateProblem = ProblemInterface & {
  __typename?: 'PlaceUpdateProblem';
  message: Scalars['String'];
};

export enum Priority {
  High = 'HIGH',
  Highest = 'HIGHEST',
  Low = 'LOW',
  Middle = 'MIDDLE'
}

export type ProblemInterface = {
  message: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  ages?: Maybe<Array<Age>>;
  article?: Maybe<Article>;
  articles?: Maybe<ArticleConnection>;
  cart?: Maybe<Cart>;
  cartAll?: Maybe<Array<CartDto>>;
  carts?: Maybe<CartConnection>;
  clubBalance?: Maybe<ClubBalance>;
  clubBalances?: Maybe<ClubBalanceConnection>;
  coachMyPaymentsByTraining?: Maybe<CoachPaymentByTrainingConnection>;
  coachPaymentByMonth?: Maybe<CoachPaymentByMonth>;
  coachPaymentByTeam?: Maybe<CoachPaymentByTeam>;
  coachPaymentByTraining?: Maybe<CoachPaymentByTraining>;
  coachPaymentRules?: Maybe<Array<CoachPaymentRule>>;
  coachPaymentsByMonth?: Maybe<CoachPaymentByMonthConnection>;
  coachPaymentsByTeam?: Maybe<CoachPaymentByTeamConnection>;
  coachPaymentsByTraining?: Maybe<CoachPaymentByTrainingConnection>;
  creator?: Maybe<Creator>;
  creatorAll?: Maybe<Array<CreatorDto>>;
  creatorAllWithCardDetail?: Maybe<Array<CreatorDto>>;
  creators?: Maybe<CreatorConnection>;
  currentMoneyCoach?: Maybe<MoneyCoach>;
  currentTasks?: Maybe<Array<Task>>;
  daysOfWeek: Array<WeekDay>;
  departments?: Maybe<Array<Department>>;
  isPublishedUser: Scalars['Boolean'];
  kit?: Maybe<Kit>;
  kitAll?: Maybe<Array<KitDto>>;
  kits?: Maybe<KitConnection>;
  lead?: Maybe<Lead>;
  leadAll?: Maybe<Array<LeadDto>>;
  leadSources: Array<SourceLead>;
  leadStatuses: Array<StatusLead>;
  leads?: Maybe<LeadConnection>;
  leadsForTeam?: Maybe<Array<LeadDto>>;
  leadsForTraining?: Maybe<Array<Lead>>;
  moneyCoach?: Maybe<MoneyCoach>;
  moneyCoaches?: Maybe<MoneyCoachConnection>;
  moneyCost?: Maybe<MoneyCost>;
  moneyCosts?: Maybe<MoneyCostConnection>;
  moneyForms?: Maybe<Array<StatusMoneyForm>>;
  moneyMove?: Maybe<MoneyMove>;
  moneyMoves?: Maybe<MoneyMoveConnection>;
  myMoneyCoaches?: Maybe<Array<MoneyCoach>>;
  nearestHeadCoachStudentBirthdays?: Maybe<Array<Student>>;
  nearestStaffBirthdays?: Maybe<Array<Staff>>;
  nearestStudentBirthdays?: Maybe<Array<Student>>;
  order?: Maybe<Order>;
  orderStatuses?: Maybe<Array<OrderStatus>>;
  orders?: Maybe<OrderConnection>;
  place?: Maybe<Place>;
  placeAll?: Maybe<Array<PlaceDto>>;
  places: PlaceConnection;
  priority?: Maybe<Array<Priority>>;
  rentPaymentByMonth?: Maybe<RentPaymentByMonth>;
  rentPaymentByTraining?: Maybe<RentPaymentByTraining>;
  rentPaymentsByMonth?: Maybe<RentPaymentByMonthConnection>;
  rentPaymentsByTraining?: Maybe<RentPaymentByTrainingConnection>;
  roles?: Maybe<Array<Role>>;
  stadium?: Maybe<Stadium>;
  stadiumAll?: Maybe<Array<StadiumDto>>;
  stadiums?: Maybe<StadiumConnection>;
  staff?: Maybe<StaffConnection>;
  staffAll?: Maybe<Array<StaffDto>>;
  staffPerson?: Maybe<Staff>;
  student?: Maybe<Student>;
  studentAll?: Maybe<Array<StudentDto>>;
  studentByCreator?: Maybe<Student>;
  studentVisit?: Maybe<StudentVisit>;
  studentVisits?: Maybe<StudentVisitConnection>;
  studentVisitsByTraining?: Maybe<Array<StudentVisit>>;
  students?: Maybe<StudentConnection>;
  studentsByTeam?: Maybe<Array<Student>>;
  task?: Maybe<Task>;
  taskStatuses?: Maybe<Array<TaskStatus>>;
  tasks?: Maybe<TaskConnection>;
  team?: Maybe<Team>;
  teamAll?: Maybe<Array<TeamDto>>;
  teamBalance?: Maybe<TeamBalance>;
  teamBalances?: Maybe<TeamBalanceConnection>;
  teams?: Maybe<TeamConnection>;
  training?: Maybe<Training>;
  trainingAll?: Maybe<Array<TrainingDto>>;
  trainingDay?: Maybe<TrainingDay>;
  trainingDays?: Maybe<TrainingDayConnection>;
  trainings?: Maybe<TrainingConnection>;
  trainingsByDay?: Maybe<Array<Training>>;
  trainingsByDayAndUser?: Maybe<Array<Training>>;
  trainingsByMonth?: Maybe<Array<Training>>;
  unPayedStudents?: Maybe<Array<Student>>;
  user: User;
  userAll?: Maybe<Array<UserDto>>;
  users?: Maybe<UserConnection>;
  visitStatuses?: Maybe<Array<VisitStatus>>;
};


export type QueryArticleArgs = {
  id: Scalars['ID'];
};


export type QueryArticlesArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<ArticleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCartArgs = {
  id: Scalars['ID'];
};


export type QueryCartsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<CartFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryClubBalanceArgs = {
  id: Scalars['ID'];
};


export type QueryClubBalancesArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<ClubBalanceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCoachMyPaymentsByTrainingArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<CoachPaymentByTrainingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCoachPaymentByMonthArgs = {
  id: Scalars['ID'];
};


export type QueryCoachPaymentByTeamArgs = {
  id: Scalars['ID'];
};


export type QueryCoachPaymentByTrainingArgs = {
  id: Scalars['ID'];
};


export type QueryCoachPaymentsByMonthArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<CoachPaymentByMonthFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCoachPaymentsByTeamArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<CoachPaymentByTeamFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCoachPaymentsByTrainingArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<CoachPaymentByTrainingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryCreatorArgs = {
  Id?: InputMaybe<Scalars['ID']>;
};


export type QueryCreatorsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<CreatorFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryIsPublishedUserArgs = {
  phone: Scalars['String'];
};


export type QueryKitArgs = {
  id: Scalars['ID'];
};


export type QueryKitsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<KitFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryLeadArgs = {
  id: Scalars['ID'];
};


export type QueryLeadsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<LeadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryLeadsForTeamArgs = {
  team: Scalars['ID'];
};


export type QueryLeadsForTrainingArgs = {
  training: Scalars['ID'];
};


export type QueryMoneyCoachArgs = {
  id: Scalars['ID'];
};


export type QueryMoneyCoachesArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<MoneyCoachFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryMoneyCostArgs = {
  id: Scalars['ID'];
};


export type QueryMoneyCostsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<MoneyCostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryMoneyMoveArgs = {
  id: Scalars['ID'];
};


export type QueryMoneyMovesArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<MoneyMoveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryNearestHeadCoachStudentBirthdaysArgs = {
  after: Scalars['Int'];
  before: Scalars['Int'];
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<OrderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryPlaceArgs = {
  id: Scalars['ID'];
};


export type QueryPlacesArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<PlaceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryRentPaymentByMonthArgs = {
  id: Scalars['ID'];
};


export type QueryRentPaymentByTrainingArgs = {
  id: Scalars['ID'];
};


export type QueryRentPaymentsByMonthArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<RentPaymentByMonthFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryRentPaymentsByTrainingArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<RentPaymentByTrainingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryStadiumArgs = {
  id: Scalars['ID'];
};


export type QueryStadiumsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<StadiumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryStaffArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<StaffFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryStaffPersonArgs = {
  id: Scalars['ID'];
};


export type QueryStudentArgs = {
  id: Scalars['ID'];
};


export type QueryStudentByCreatorArgs = {
  creator: Scalars['ID'];
};


export type QueryStudentVisitArgs = {
  id: Scalars['ID'];
};


export type QueryStudentVisitsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<StudentVisitFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryStudentVisitsByTrainingArgs = {
  training: Scalars['ID'];
};


export type QueryStudentsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<StudentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryStudentsByTeamArgs = {
  team: Scalars['ID'];
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryTasksArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<TaskFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryTeamArgs = {
  id: Scalars['ID'];
};


export type QueryTeamBalanceArgs = {
  id: Scalars['ID'];
};


export type QueryTeamBalancesArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<TeamBalanceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryTeamsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<TeamFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryTrainingArgs = {
  id: Scalars['ID'];
};


export type QueryTrainingDayArgs = {
  id: Scalars['ID'];
};


export type QueryTrainingDaysArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<TrainingDayFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryTrainingsArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<TrainingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryTrainingsByDayArgs = {
  date: Scalars['Date'];
};


export type QueryTrainingsByDayAndUserArgs = {
  date: Scalars['Date'];
};


export type QueryTrainingsByMonthArgs = {
  date: Scalars['Date'];
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type RegisterInput = {
  confirmPassword: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};

export type RentPaymentByMonth = {
  __typename?: 'RentPaymentByMonth';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  month: Scalars['Time'];
  paymentDate: Scalars['Time'];
  published: Scalars['Boolean'];
  stadium: Stadium;
  stadiumId: Scalars['ID'];
  sum: Scalars['Int'];
};

export type RentPaymentByMonthConnection = {
  __typename?: 'RentPaymentByMonthConnection';
  edges: Array<RentPaymentByMonthEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RentPaymentByMonthDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'RentPaymentByMonthDoesNotExistsProblem';
  message: Scalars['String'];
};

export type RentPaymentByMonthEdge = {
  __typename?: 'RentPaymentByMonthEdge';
  cursor: Scalars['ID'];
  node?: Maybe<RentPaymentByMonth>;
};

export type RentPaymentByMonthFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type RentPaymentByMonthInput = {
  description?: InputMaybe<Scalars['String']>;
  month: Scalars['Time'];
  paymentDate: Scalars['Time'];
  published: Scalars['Boolean'];
  stadiumId: Scalars['ID'];
  sum: Scalars['Int'];
};

export type RentPaymentByMonthInputWithId = {
  id: Scalars['ID'];
  input: RentPaymentByMonthInput;
};

export type RentPaymentByMonthPayload = {
  __typename?: 'RentPaymentByMonthPayload';
  errors?: Maybe<Array<RentPaymentByMonthProblems>>;
  record: RentPaymentByMonth;
  recordId: Scalars['ID'];
};

export type RentPaymentByMonthProblems = RentPaymentByMonthDoesNotExistsProblem | RentPaymentByMonthSaveProblem | RentPaymentByMonthUpdateProblem;

export type RentPaymentByMonthSaveProblem = ProblemInterface & {
  __typename?: 'RentPaymentByMonthSaveProblem';
  message: Scalars['String'];
};

export type RentPaymentByMonthUpdateProblem = ProblemInterface & {
  __typename?: 'RentPaymentByMonthUpdateProblem';
  message: Scalars['String'];
};

export type RentPaymentByTraining = {
  __typename?: 'RentPaymentByTraining';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  published: Scalars['Boolean'];
  stadium: Stadium;
  stadiumId: Scalars['ID'];
  sum: Scalars['Int'];
  timeFinish: Scalars['Time'];
  timeStart: Scalars['Time'];
  trainingIds: Array<Scalars['ID']>;
  trainings: Array<Training>;
};

export type RentPaymentByTrainingConnection = {
  __typename?: 'RentPaymentByTrainingConnection';
  edges: Array<RentPaymentByTrainingEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type RentPaymentByTrainingDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'RentPaymentByTrainingDoesNotExistsProblem';
  message: Scalars['String'];
};

export type RentPaymentByTrainingEdge = {
  __typename?: 'RentPaymentByTrainingEdge';
  cursor: Scalars['ID'];
  node?: Maybe<RentPaymentByTraining>;
};

export type RentPaymentByTrainingFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type RentPaymentByTrainingInput = {
  description?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
  stadiumId: Scalars['ID'];
  sum: Scalars['Int'];
  timeFinish: Scalars['Time'];
  timeStart: Scalars['Time'];
  trainingIds: Array<Scalars['ID']>;
};

export type RentPaymentByTrainingInputWithId = {
  id: Scalars['ID'];
  input: RentPaymentByTrainingInput;
};

export type RentPaymentByTrainingPayload = {
  __typename?: 'RentPaymentByTrainingPayload';
  errors?: Maybe<Array<RentPaymentByTrainingProblems>>;
  record: RentPaymentByTraining;
  recordId: Scalars['ID'];
};

export type RentPaymentByTrainingProblems = RentPaymentByTrainingDoesNotExistsProblem | RentPaymentByTrainingSaveProblem | RentPaymentByTrainingUpdateProblem;

export type RentPaymentByTrainingSaveProblem = ProblemInterface & {
  __typename?: 'RentPaymentByTrainingSaveProblem';
  message: Scalars['String'];
};

export type RentPaymentByTrainingUpdateProblem = ProblemInterface & {
  __typename?: 'RentPaymentByTrainingUpdateProblem';
  message: Scalars['String'];
};

export enum Role {
  Admin = 'ADMIN',
  Coach = 'COACH',
  Director = 'DIRECTOR',
  Economist = 'ECONOMIST',
  Editor = 'EDITOR',
  User = 'USER'
}

export type SourceLead = {
  __typename?: 'SourceLead';
  label: Scalars['String'];
  value: LeadSource;
};

export type Stadium = {
  __typename?: 'Stadium';
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  paymentMonthSum: Scalars['Int'];
  paymentTrainingSum: Scalars['Int'];
  place: Place;
  placeId: Scalars['ID'];
  published: Scalars['Boolean'];
};

export type StadiumConnection = {
  __typename?: 'StadiumConnection';
  edges: Array<StadiumEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type StadiumDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'StadiumDoesNotExistsProblem';
  message: Scalars['String'];
};

export type StadiumDto = {
  __typename?: 'StadiumDto';
  label: Scalars['String'];
  value: Scalars['ID'];
};

export type StadiumEdge = {
  __typename?: 'StadiumEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Stadium>;
};

export type StadiumExistInOtherComponentsProblem = ProblemInterface & {
  __typename?: 'StadiumExistInOtherComponentsProblem';
  component: Scalars['String'];
  message: Scalars['String'];
};

export type StadiumFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type StadiumInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  paymentMonthSum: Scalars['Int'];
  paymentTrainingSum: Scalars['Int'];
  placeId: Scalars['ID'];
  published: Scalars['Boolean'];
};

export type StadiumInputWithId = {
  id: Scalars['ID'];
  input: StadiumInput;
};

export type StadiumNameExistsProblem = ProblemInterface & {
  __typename?: 'StadiumNameExistsProblem';
  message: Scalars['String'];
};

export type StadiumPayload = {
  __typename?: 'StadiumPayload';
  errors?: Maybe<Array<StadiumProblems>>;
  record: Stadium;
  recordId: Scalars['ID'];
};

export type StadiumProblems = StadiumDoesNotExistsProblem | StadiumExistInOtherComponentsProblem | StadiumNameExistsProblem | StadiumSaveProblem | StadiumUpdateProblem;

export type StadiumSaveProblem = ProblemInterface & {
  __typename?: 'StadiumSaveProblem';
  message: Scalars['String'];
};

export type StadiumUpdateProblem = ProblemInterface & {
  __typename?: 'StadiumUpdateProblem';
  message: Scalars['String'];
};

export type Staff = {
  __typename?: 'Staff';
  birthday?: Maybe<Scalars['Time']>;
  department: Department;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  orderNumber: Scalars['Int'];
  phone?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  user: User;
  userId: Scalars['ID'];
  work: Scalars['String'];
};

export type StaffConnection = {
  __typename?: 'StaffConnection';
  edges: Array<StaffEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type StaffDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'StaffDoesNotExistsProblem';
  message: Scalars['String'];
};

export type StaffDto = {
  __typename?: 'StaffDto';
  label: Scalars['String'];
  value: Scalars['ID'];
};

export type StaffEdge = {
  __typename?: 'StaffEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Staff>;
};

export type StaffExistInOtherComponentsProblem = ProblemInterface & {
  __typename?: 'StaffExistInOtherComponentsProblem';
  component: Scalars['String'];
  message: Scalars['String'];
};

export type StaffFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type StaffInput = {
  birthday?: InputMaybe<Scalars['Time']>;
  department: Department;
  description?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  orderNumber: Scalars['Int'];
  phone?: InputMaybe<Scalars['String']>;
  published: Scalars['Boolean'];
  userId: Scalars['ID'];
  work: Scalars['String'];
};

export type StaffInputWithId = {
  id: Scalars['ID'];
  input: StaffInput;
};

export type StaffNameExistsProblem = ProblemInterface & {
  __typename?: 'StaffNameExistsProblem';
  message: Scalars['String'];
};

export type StaffPayload = {
  __typename?: 'StaffPayload';
  errors?: Maybe<Array<StaffProblems>>;
  record: Staff;
  recordId: Scalars['ID'];
};

export type StaffProblems = StaffDoesNotExistsProblem | StaffExistInOtherComponentsProblem | StaffNameExistsProblem | StaffSaveProblem | StaffUpdateProblem;

export type StaffSaveProblem = ProblemInterface & {
  __typename?: 'StaffSaveProblem';
  message: Scalars['String'];
};

export type StaffUpdateProblem = ProblemInterface & {
  __typename?: 'StaffUpdateProblem';
  message: Scalars['String'];
};

export type StatusLead = {
  __typename?: 'StatusLead';
  label: Scalars['String'];
  value: LeadStatus;
};

export type StatusMoneyForm = {
  __typename?: 'StatusMoneyForm';
  label: Scalars['String'];
  value: MoneyForm;
};

export type Student = {
  __typename?: 'Student';
  address?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['Time']>;
  creatorIds: Array<Scalars['ID']>;
  creators?: Maybe<Array<Creator>>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastVisits?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  passportNum?: Maybe<Scalars['String']>;
  paymentSum?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  school?: Maybe<Scalars['String']>;
  teamIds: Array<Scalars['ID']>;
  teams?: Maybe<Array<Team>>;
};

export type StudentConnection = {
  __typename?: 'StudentConnection';
  edges: Array<StudentEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type StudentDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'StudentDoesNotExistsProblem';
  message: Scalars['String'];
};

export type StudentDto = {
  __typename?: 'StudentDto';
  label: Scalars['String'];
  value: Scalars['ID'];
};

export type StudentEdge = {
  __typename?: 'StudentEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Student>;
};

export type StudentExistInOtherComponentsProblem = ProblemInterface & {
  __typename?: 'StudentExistInOtherComponentsProblem';
  component: Scalars['String'];
  message: Scalars['String'];
};

export type StudentFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type StudentInput = {
  address?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['Time']>;
  creatorIds: Array<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  passportNum?: InputMaybe<Scalars['String']>;
  paymentSum?: InputMaybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  school?: InputMaybe<Scalars['String']>;
  teamIds: Array<Scalars['ID']>;
};

export type StudentInputWithId = {
  id: Scalars['ID'];
  input: StudentInput;
};

export type StudentPayload = {
  __typename?: 'StudentPayload';
  errors?: Maybe<Array<StudentProblems>>;
  record: Student;
  recordId: Scalars['ID'];
};

export type StudentProblems = StudentDoesNotExistsProblem | StudentExistInOtherComponentsProblem | StudentSaveProblem | StudentUpdateProblem;

export type StudentSaveProblem = ProblemInterface & {
  __typename?: 'StudentSaveProblem';
  message: Scalars['String'];
};

export type StudentUpdateProblem = ProblemInterface & {
  __typename?: 'StudentUpdateProblem';
  message: Scalars['String'];
};

export type StudentVisit = {
  __typename?: 'StudentVisit';
  id: Scalars['ID'];
  payed: Scalars['Boolean'];
  published: Scalars['Boolean'];
  student: Student;
  studentId: Scalars['ID'];
  training: Training;
  trainingId: Scalars['ID'];
  visitStatus: VisitStatus;
};

export type StudentVisitConnection = {
  __typename?: 'StudentVisitConnection';
  edges: Array<StudentVisitEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type StudentVisitDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'StudentVisitDoesNotExistsProblem';
  message: Scalars['String'];
};

export type StudentVisitEdge = {
  __typename?: 'StudentVisitEdge';
  cursor: Scalars['ID'];
  node?: Maybe<StudentVisit>;
};

export type StudentVisitFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type StudentVisitInput = {
  payed: Scalars['Boolean'];
  published: Scalars['Boolean'];
  studentId: Scalars['ID'];
  trainingId: Scalars['ID'];
  visitStatus: VisitStatus;
};

export type StudentVisitInputWithId = {
  id: Scalars['ID'];
  input: StudentVisitInput;
};

export type StudentVisitPayload = {
  __typename?: 'StudentVisitPayload';
  errors?: Maybe<Array<StudentVisitProblems>>;
  record: StudentVisit;
  recordId: Scalars['ID'];
};

export type StudentVisitProblems = StudentVisitDoesNotExistsProblem | StudentVisitSaveProblem | StudentVisitUpdateProblem;

export type StudentVisitSaveProblem = ProblemInterface & {
  __typename?: 'StudentVisitSaveProblem';
  message: Scalars['String'];
};

export type StudentVisitUpdateProblem = ProblemInterface & {
  __typename?: 'StudentVisitUpdateProblem';
  message: Scalars['String'];
};

export type Task = {
  __typename?: 'Task';
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  leadIds?: Maybe<Array<Scalars['ID']>>;
  leads?: Maybe<Array<Lead>>;
  priority?: Maybe<Priority>;
  published: Scalars['Boolean'];
  result?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Time']>;
  studentIds?: Maybe<Array<Scalars['ID']>>;
  students?: Maybe<Array<Student>>;
  taskStatus?: Maybe<TaskStatus>;
  title: Scalars['String'];
  workerIds?: Maybe<Array<Scalars['ID']>>;
  workers?: Maybe<Array<Maybe<Staff>>>;
};

export type TaskConnection = {
  __typename?: 'TaskConnection';
  edges: Array<TaskEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TaskDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'TaskDoesNotExistsProblem';
  message: Scalars['String'];
};

export type TaskEdge = {
  __typename?: 'TaskEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Task>;
};

export type TaskExistInOtherComponentsProblem = ProblemInterface & {
  __typename?: 'TaskExistInOtherComponentsProblem';
  component: Scalars['String'];
  message: Scalars['String'];
};

export type TaskFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type TaskInput = {
  authorId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['Time']>;
  leadIds?: InputMaybe<Array<Scalars['ID']>>;
  priority?: InputMaybe<Priority>;
  published: Scalars['Boolean'];
  result?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['Time']>;
  studentIds?: InputMaybe<Array<Scalars['ID']>>;
  taskStatus?: InputMaybe<TaskStatus>;
  title: Scalars['String'];
  workerIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type TaskInputWithId = {
  id: Scalars['ID'];
  input: TaskInput;
};

export type TaskPayload = {
  __typename?: 'TaskPayload';
  errors?: Maybe<Array<TaskProblems>>;
  record: Task;
  recordId: Scalars['ID'];
};

export type TaskProblems = TaskDoesNotExistsProblem | TaskExistInOtherComponentsProblem | TaskSaveProblem | TaskUpdateProblem;

export type TaskSaveProblem = ProblemInterface & {
  __typename?: 'TaskSaveProblem';
  message: Scalars['String'];
};

export enum TaskStatus {
  Cancel = 'CANCEL',
  Done = 'DONE',
  New = 'NEW',
  Work = 'WORK'
}

export type TaskUpdateProblem = ProblemInterface & {
  __typename?: 'TaskUpdateProblem';
  message: Scalars['String'];
};

export type Team = {
  __typename?: 'Team';
  ages: Array<Age>;
  coachIds?: Maybe<Array<Scalars['ID']>>;
  coaches?: Maybe<Array<Staff>>;
  headCoach?: Maybe<Staff>;
  headCoachId?: Maybe<Scalars['ID']>;
  headCoachPayment?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  place: Place;
  placeId: Scalars['ID'];
  published: Scalars['Boolean'];
  writable: Scalars['Boolean'];
};

export type TeamBalance = {
  __typename?: 'TeamBalance';
  date: Scalars['Time'];
  id: Scalars['ID'];
  published: Scalars['Boolean'];
  rent: Scalars['Int'];
  salary: Scalars['Int'];
  sum: Scalars['Int'];
  team: Team;
  teamId: Scalars['ID'];
  tickets: Scalars['Int'];
};

export type TeamBalanceConnection = {
  __typename?: 'TeamBalanceConnection';
  edges: Array<TeamBalanceEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TeamBalanceDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'TeamBalanceDoesNotExistsProblem';
  message: Scalars['String'];
};

export type TeamBalanceEdge = {
  __typename?: 'TeamBalanceEdge';
  cursor: Scalars['ID'];
  node?: Maybe<TeamBalance>;
};

export type TeamBalanceFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type TeamBalanceInput = {
  date: Scalars['Time'];
  published: Scalars['Boolean'];
  rent: Scalars['Int'];
  salary: Scalars['Int'];
  teamId: Scalars['ID'];
  tickets: Scalars['Int'];
};

export type TeamBalanceInputWithId = {
  id: Scalars['ID'];
  input: TeamBalanceInput;
};

export type TeamBalancePayload = {
  __typename?: 'TeamBalancePayload';
  errors?: Maybe<Array<TeamBalanceProblems>>;
  record: TeamBalance;
  recordId: Scalars['ID'];
};

export type TeamBalanceProblems = TeamBalanceDoesNotExistsProblem | TeamBalanceSaveProblem | TeamBalanceUpdateProblem;

export type TeamBalanceSaveProblem = ProblemInterface & {
  __typename?: 'TeamBalanceSaveProblem';
  message: Scalars['String'];
};

export type TeamBalanceUpdateProblem = ProblemInterface & {
  __typename?: 'TeamBalanceUpdateProblem';
  message: Scalars['String'];
};

export type TeamConnection = {
  __typename?: 'TeamConnection';
  edges: Array<TeamEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TeamDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'TeamDoesNotExistsProblem';
  message: Scalars['String'];
};

export type TeamDto = {
  __typename?: 'TeamDto';
  label: Scalars['String'];
  value: Scalars['ID'];
};

export type TeamEdge = {
  __typename?: 'TeamEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Team>;
};

export type TeamExistInOtherComponentsProblem = ProblemInterface & {
  __typename?: 'TeamExistInOtherComponentsProblem';
  component: Scalars['String'];
  message: Scalars['String'];
};

export type TeamFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type TeamInput = {
  ages?: InputMaybe<Array<Age>>;
  coachIds?: InputMaybe<Array<Scalars['ID']>>;
  headCoachId?: InputMaybe<Scalars['ID']>;
  headCoachPayment?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  placeId: Scalars['ID'];
  published: Scalars['Boolean'];
  writable: Scalars['Boolean'];
};

export type TeamInputWithId = {
  id: Scalars['ID'];
  input: TeamInput;
};

export type TeamNameExistsProblem = ProblemInterface & {
  __typename?: 'TeamNameExistsProblem';
  message: Scalars['String'];
};

export type TeamPayload = {
  __typename?: 'TeamPayload';
  errors?: Maybe<Array<TeamProblems>>;
  record: Team;
  recordId: Scalars['ID'];
};

export type TeamProblems = TeamDoesNotExistsProblem | TeamExistInOtherComponentsProblem | TeamNameExistsProblem | TeamSaveProblem | TeamUpdateProblem;

export type TeamSaveProblem = ProblemInterface & {
  __typename?: 'TeamSaveProblem';
  message: Scalars['String'];
};

export type TeamUpdateProblem = ProblemInterface & {
  __typename?: 'TeamUpdateProblem';
  message: Scalars['String'];
};

export type Token = {
  __typename?: 'Token';
  accessToken: Scalars['String'];
  expiration: Scalars['Time'];
  refreshToken: Scalars['String'];
};

export type Training = {
  __typename?: 'Training';
  coachIds?: Maybe<Array<Scalars['ID']>>;
  coaches?: Maybe<Array<Staff>>;
  headCoach?: Maybe<Staff>;
  headCoachId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  published: Scalars['Boolean'];
  rentPaymentSum: Scalars['Int'];
  stadium?: Maybe<Stadium>;
  stadiumId?: Maybe<Scalars['ID']>;
  team: Team;
  teamId: Scalars['ID'];
  time?: Maybe<Scalars['Time']>;
  visits: Scalars['Int'];
};

export type TrainingCoachesAndVisitsInput = {
  coachIds?: InputMaybe<Array<Scalars['ID']>>;
  headCoachID: Scalars['ID'];
  trainingId: Scalars['ID'];
};

export type TrainingConnection = {
  __typename?: 'TrainingConnection';
  edges: Array<TrainingEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TrainingDay = {
  __typename?: 'TrainingDay';
  coachPayment?: Maybe<Scalars['Int']>;
  day?: Maybe<DayOfWeek>;
  headCoachPayment?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  published: Scalars['Boolean'];
  stadium?: Maybe<Stadium>;
  stadiumId?: Maybe<Scalars['ID']>;
  team: Team;
  teamId: Scalars['ID'];
  time?: Maybe<Scalars['HourMinute']>;
};

export type TrainingDayConnection = {
  __typename?: 'TrainingDayConnection';
  edges: Array<TrainingDayEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TrainingDayDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'TrainingDayDoesNotExistsProblem';
  message: Scalars['String'];
};

export type TrainingDayEdge = {
  __typename?: 'TrainingDayEdge';
  cursor: Scalars['ID'];
  node?: Maybe<TrainingDay>;
};

export type TrainingDayFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type TrainingDayInput = {
  coachPayment?: InputMaybe<Scalars['Int']>;
  day?: InputMaybe<DayOfWeek>;
  headCoachPayment?: InputMaybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  stadiumId?: InputMaybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  time?: InputMaybe<Scalars['HourMinute']>;
};

export type TrainingDayInputWithId = {
  id: Scalars['ID'];
  input: TrainingDayInput;
};

export type TrainingDayPayload = {
  __typename?: 'TrainingDayPayload';
  errors?: Maybe<Array<TrainingDayProblems>>;
  record: TrainingDay;
  recordId: Scalars['ID'];
};

export type TrainingDayProblems = TrainingDayDoesNotExistsProblem | TrainingDaySaveProblem | TrainingDayUpdateProblem;

export type TrainingDaySaveProblem = ProblemInterface & {
  __typename?: 'TrainingDaySaveProblem';
  message: Scalars['String'];
};

export type TrainingDayUpdateProblem = ProblemInterface & {
  __typename?: 'TrainingDayUpdateProblem';
  message: Scalars['String'];
};

export type TrainingDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'TrainingDoesNotExistsProblem';
  message: Scalars['String'];
};

export type TrainingDto = {
  __typename?: 'TrainingDto';
  label: Scalars['String'];
  value: Scalars['ID'];
};

export type TrainingEdge = {
  __typename?: 'TrainingEdge';
  cursor: Scalars['ID'];
  node?: Maybe<Training>;
};

export type TrainingExistInOtherComponentsProblem = ProblemInterface & {
  __typename?: 'TrainingExistInOtherComponentsProblem';
  component: Scalars['String'];
  message: Scalars['String'];
};

export type TrainingFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type TrainingInput = {
  coachIds?: InputMaybe<Array<Scalars['ID']>>;
  headCoachId?: InputMaybe<Scalars['ID']>;
  published: Scalars['Boolean'];
  rentPaymentSum: Scalars['Int'];
  stadiumId?: InputMaybe<Scalars['ID']>;
  teamId: Scalars['ID'];
  time: Scalars['Time'];
  visits: Scalars['Int'];
};

export type TrainingInputWithId = {
  id: Scalars['ID'];
  input: TrainingInput;
};

export type TrainingPayload = {
  __typename?: 'TrainingPayload';
  errors?: Maybe<Array<TrainingProblems>>;
  record: Training;
  recordId: Scalars['ID'];
};

export type TrainingProblems = TrainingDoesNotExistsProblem | TrainingExistInOtherComponentsProblem | TrainingSaveProblem | TrainingUpdateProblem;

export type TrainingSaveProblem = ProblemInterface & {
  __typename?: 'TrainingSaveProblem';
  message: Scalars['String'];
};

export type TrainingUpdateProblem = ProblemInterface & {
  __typename?: 'TrainingUpdateProblem';
  message: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  roles: Array<Role>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserDoesNotExistsProblem = ProblemInterface & {
  __typename?: 'UserDoesNotExistsProblem';
  message: Scalars['String'];
};

export type UserDto = {
  __typename?: 'UserDto';
  label: Scalars['String'];
  value: Scalars['ID'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['ID'];
  node?: Maybe<User>;
};

export type UserExistInOtherComponentsProblem = ProblemInterface & {
  __typename?: 'UserExistInOtherComponentsProblem';
  component: Scalars['String'];
  message: Scalars['String'];
};

export type UserFilter = {
  name?: InputMaybe<Scalars['String']>;
};

export type UserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  roles: Array<Role>;
};

export type UserInputWithId = {
  id: Scalars['ID'];
  input: UserInput;
};

export type UserNameExistsProblem = ProblemInterface & {
  __typename?: 'UserNameExistsProblem';
  message: Scalars['String'];
};

export type UserPassword = {
  confirmPassword: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
};

export type UserPayload = {
  __typename?: 'UserPayload';
  errors?: Maybe<Array<UserProblems>>;
  record: User;
  recordId: Scalars['ID'];
};

export type UserProblems = UserDoesNotExistsProblem | UserExistInOtherComponentsProblem | UserNameExistsProblem | UserSaveProblem | UserUpdateProblem;

export type UserSaveProblem = ProblemInterface & {
  __typename?: 'UserSaveProblem';
  message: Scalars['String'];
};

export type UserUpdateProblem = ProblemInterface & {
  __typename?: 'UserUpdateProblem';
  message: Scalars['String'];
};

export enum VisitStatus {
  Absent = 'ABSENT',
  Holiday = 'HOLIDAY',
  Ill = 'ILL',
  Visited = 'VISITED'
}

export type WeekDay = {
  __typename?: 'WeekDay';
  label: Scalars['String'];
  value: DayOfWeek;
};
